import React from 'react';
import { motion } from 'framer-motion';
import { FaHourglassHalf, FaCalendarAlt } from 'react-icons/fa'; 
import { format, isWeekend, parseISO } from 'date-fns';
import '../../stylez/DayWorkedCard.css';

function DayWorkedCard({ date, events }) {
  const dateObj = new Date(date);
  const dayName = format(dateObj, 'EEEE');
  const formattedDate = format(dateObj, 'MM/dd/yyyy');
  const hoursWorked = events.length; // Assuming each event is 1 hour
  const isWeekendDay = isWeekend(dateObj);
  
  // Get unique class types from events
  const classTypes = [...new Set(events.map(event => event.title || 'Class'))];
  
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    },
    hover: {
      y: -5,
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.12)",
      transition: {
        duration: 0.2
      }
    }
  };

  const iconVariants = {
    hidden: { scale: 0, rotate: -45 },
    visible: { 
      scale: 1, 
      rotate: 0,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        delay: 0.1
      }
    }
  };

  return (
    <motion.div 
      className="day-worked-card"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
    >
      <div className="day-indicator" 
           style={{ backgroundColor: isWeekendDay ? '#e74c3c' : '#3f72af' }} 
      />
      
      <motion.div variants={iconVariants}>
        <FaHourglassHalf className="hourglass-card-icon" />
      </motion.div>
      
      <div className="divider"></div>
      
      <div className="hours-content">
        <h3>
          {/* <FaCalendarAlt style={{ marginRight: '5px', fontSize: '14px' }} /> */}
          {dayName}, {formattedDate}
        </h3>
        <p>{hoursWorked} {hoursWorked === 1 ? 'hour' : 'hours'}</p>
        
        <div className="hours-details">
          {/* {classTypes.map((type, index) => (
            <span key={index}>
              {type}{index < classTypes.length - 1 ? ', ' : ''}
            </span>
          ))} */}
        </div>
      </div>
    </motion.div>
  );
}

export default DayWorkedCard;