// src/react-query/usePushNotifications.js
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useAuth } from './useAuth';
import { 
  saveSubscription, 
  removeSubscription, 
  sendPushNotification,
  broadcastNotification,
  getSubscribedUsers,
  getMySubscription,
  removeAllSubscriptions,
  togglePushNotifications,
  getPushNotificationsStatus
} from '../services/api_push';
import { toast } from 'react-toastify';
import { subscribeToPushNotifications } from '../serviceWorkerRegistration';

export const usePushNotifications = () => {
  const queryClient = useQueryClient();
  const { userData } = useAuth();

  // Query to fetch subscribed users (admin only)
  const subscribedUsersQuery = useQuery(
    'subscribedUsers', 
    getSubscribedUsers, 
    {
      // Only fetch if user is an admin
      enabled: userData?.is_admin === true,
      staleTime: 60000, // 1 minute
      refetchOnWindowFocus: userData?.is_admin === true, // Only refetch for admins
      onError: (error) => {
        console.error('Error fetching subscribed users:', error);
        // Only show error for admins
        if (userData?.is_admin) {
          toast.error('Failed to load users with notification access');
        }
      }
    }
  );
  
  // Query to fetch current user's subscription info (for regular users)
  const mySubscriptionQuery = useQuery(
    'mySubscription',
    getMySubscription,
    {
      staleTime: 60000, // 1 minute
      refetchOnWindowFocus: true,
      onError: (error) => {
        console.error('Error fetching user subscription info:', error);
        // Don't show error toast to user for this one - handled gracefully
      }
    }
  );
  
  // Query to fetch system-wide push notification status (admin only)
  const pushNotificationsStatusQuery = useQuery(
    'pushNotificationsStatus',
    getPushNotificationsStatus,
    {
      // Only fetch if user is an admin
      enabled: userData?.is_admin === true,
      staleTime: 60000, // 1 minute
      refetchOnWindowFocus: userData?.is_admin === true, // Only refetch for admins
      onError: (error) => {
        console.error('Error fetching push notifications status:', error);
        // Only show error for admins
        if (userData?.is_admin) {
          toast.error('Failed to load system notification status');
        }
      }
    }
  );

  // Mutation for saving a subscription to the server
  const saveSubscriptionMutation = useMutation(saveSubscription, {
    onSuccess: () => {
      toast.success('Notifications enabled successfully!');
      // Refresh the list of subscribed users
      queryClient.invalidateQueries('subscribedUsers');
      queryClient.invalidateQueries('mySubscription');
    },
    onError: (error) => {
      console.error('Failed to save subscription on server:', error);
      toast.error('Failed to enable notifications on the server.');
    }
  });

  // Mutation for removing a subscription from the server
  const removeSubscriptionMutation = useMutation(removeSubscription, {
    onSuccess: () => {
      toast.success('Notifications disabled successfully!');
      // Refresh the list of subscribed users
      queryClient.invalidateQueries('subscribedUsers');
      queryClient.invalidateQueries('mySubscription');
    },
    onError: (error) => {
      console.error('Failed to remove subscription from server:', error);
      toast.error('Failed to disable notifications on the server.');
    }
  });

  // Mutation for sending a notification to specific users
  const sendNotificationMutation = useMutation(
    ({ notification, userIds }) => sendPushNotification(notification, userIds),
    {
      onSuccess: (data) => {
        toast.success(`Notification sent to ${data.total} recipients (${data.success} successful)`);
      },
      onError: (error) => {
        toast.error('Failed to send notification: ' + (error.response?.data?.detail || error.message));
      }
    }
  );

  // Mutation for broadcasting a notification to all users
  const broadcastNotificationMutation = useMutation(
    (notification) => broadcastNotification(notification),
    {
      onSuccess: (data) => {
        toast.success(`Notification broadcast to ${data.total} recipients (${data.success} successful)`);
      },
      onError: (error) => {
        toast.error('Failed to broadcast notification: ' + (error.response?.data?.detail || error.message));
      }
    }
  );
  
  // Mutation for removing all subscriptions for the current user
  const removeAllSubscriptionsMutation = useMutation(removeAllSubscriptions, {
    onSuccess: () => {
      toast.success("Notifications disabled on all your devices!");
      // Refresh the list of subscribed users
      queryClient.invalidateQueries("subscribedUsers");
      queryClient.invalidateQueries("mySubscription");
    },
    onError: (error) => {
      console.error("Failed to remove all subscriptions:", error);
      toast.error("Failed to disable notifications on all devices.");
    }
  });
  
  // Mutation for toggling system-wide push notifications (admin only)
  const togglePushNotificationsMutation = useMutation(
    (enable) => togglePushNotifications(enable),
    {
      onSuccess: (data) => {
        const status = data.push_notifications_enabled ? 'enabled' : 'disabled';
        toast.success(`Push notifications have been ${status} system-wide.`);
        // Refresh the status
        queryClient.invalidateQueries('pushNotificationsStatus');
      },
      onError: (error) => {
        console.error('Failed to toggle system-wide push notifications:', error);
        toast.error('Failed to update system notification settings.');
      }
    }
  );

  // Function to subscribe to push notifications
  const subscribe = async () => {
    try {
      console.log('Requesting notification permission...');
      
      // Check if we're in a PWA environment running on iOS
      const isIOSPWA = 
        window.navigator.standalone === true || // iOS Safari
        window.matchMedia('(display-mode: standalone)').matches; // Other browsers
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      
      // Get service worker with retry for iOS PWA
      if (!navigator.serviceWorker.controller) {
        console.log('Service worker not yet controlling the page');
        
        if (isIOS && isIOSPWA) {
          // For iOS PWA, try to wait for service worker to activate
          toast.info('Initializing notifications. Please wait a moment...');
          
          // Wait for service worker to be ready with a timeout
          let retries = 0;
          const maxRetries = 3;
          
          while (!navigator.serviceWorker.controller && retries < maxRetries) {
            console.log(`Waiting for service worker to activate (attempt ${retries + 1}/${maxRetries})...`);
            // Wait 1 second between attempts
            await new Promise(resolve => setTimeout(resolve, 1000));
            retries++;
          }
          
          // If still no service worker controller after retries
          if (!navigator.serviceWorker.controller) {
            // Force service worker registration refresh
            try {
              console.log('Forcing service worker registration...');
              const registrations = await navigator.serviceWorker.getRegistrations();
              for (let registration of registrations) {
                await registration.update();
              }
              
              // Wait one more second for updates to apply
              await new Promise(resolve => setTimeout(resolve, 1000));
              
              // Still no controller?
              if (!navigator.serviceWorker.controller) {
                toast.error('Could not initialize push notifications. Try closing and reopening the app.');
                return { success: false };
              }
            } catch (swError) {
              console.error('Error refreshing service worker:', swError);
              toast.error('Could not initialize notifications. Please close the app completely and reopen it.');
              return { success: false, error: swError };
            }
          }
        } else {
          // For other platforms, just suggest a reload
          toast.error('Service worker not yet active. Please reload the page and try again.');
          return { success: false };
        }
      }
      
      // Request permission directly first
      const permission = await Notification.requestPermission();
      console.log('Notification permission status:', permission);
      
      if (permission === 'granted') {
        const subscription = await subscribeToPushNotifications();
        console.log('Push subscription attempt result:', subscription);
        
        if (subscription) {
          // Save subscription to server
          await saveSubscriptionMutation.mutateAsync(subscription);
          return { success: true, subscription };
        } else {
          console.warn('Subscription failed but permission was granted');
          toast.error('Permission granted, but subscription failed. This could be due to the VAPID key or service worker issues.');
          return { success: false };
        }
      } else {
        console.warn('Notification permission was not granted:', permission);
        toast.error('Notification permission was not granted. You need to allow notifications to use this feature.');
        return { success: false, permission };
      }
    } catch (error) {
      console.error('Error subscribing to push notifications:', error);
      toast.error('Error enabling notifications: ' + error.message);
      return { success: false, error };
    }
  };

  // Function to unsubscribe from push notifications
  const unsubscribe = async (subscription) => {
    if (!subscription) return { success: false };
    
    try {
      const success = await subscription.unsubscribe();
      if (success) {
        console.log('Successfully unsubscribed from browser');
        
        // Remove subscription from server
        await removeSubscriptionMutation.mutateAsync(subscription.endpoint);
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      console.error('Error unsubscribing:', error);
      toast.error('Error disabling notifications: ' + error.message);
      return { success: false, error };
    }
  };
  
  // Function to unsubscribe from all devices
  const unsubscribeAll = async () => {
    try {
      // Remove all subscriptions from server
      await removeAllSubscriptionsMutation.mutateAsync();
      
      // Also unsubscribe the current device if it is subscribed
      const swRegistration = await navigator.serviceWorker.ready;
      const currentSubscription = await swRegistration.pushManager.getSubscription();
      
      if (currentSubscription) {
        await currentSubscription.unsubscribe();
      }
      
      return { success: true };
    } catch (error) {
      console.error("Error unsubscribing from all devices:", error);
      toast.error("Error disabling notifications on all devices: " + error.message);
      return { success: false, error };
    }
  };

  // Send notification to specific users
  const sendNotification = async (title, body, data = {}, userIds = null) => {
    const notification = {
      title,
      body,
      data
    };
    
    return await sendNotificationMutation.mutateAsync({
      notification,
      userIds
    });
  };

  // Broadcast notification to all users
  const broadcastToAll = async (title, body, data = {}) => {
    const notification = {
      title,
      body,
      data
    };
    
    return await broadcastNotificationMutation.mutateAsync(notification);
  };

  return {
    subscribe,
    unsubscribe,
    unsubscribeAll,
    sendNotification,
    broadcastToAll,
    // Admin data for managing all subscriptions
    subscribedUsers: subscribedUsersQuery.data || [],
    isLoadingUsers: subscribedUsersQuery.isLoading,
    // Current user subscription data (works for regular users)
    mySubscription: mySubscriptionQuery.data || { subscription_count: 0 },
    isLoadingMySubscription: mySubscriptionQuery.isLoading,
    // System-wide push notification status (admin only)
    pushNotificationsStatus: pushNotificationsStatusQuery.data,
    isLoadingPushNotificationsStatus: pushNotificationsStatusQuery.isLoading,
    toggleSystemPushNotifications: (enable) => togglePushNotificationsMutation.mutate(enable),
    isTogglingSystemPushNotifications: togglePushNotificationsMutation.isLoading,
    // Mutation states
    isSubscribing: saveSubscriptionMutation.isLoading,
    isUnsubscribing: removeSubscriptionMutation.isLoading,
    isUnsubscribingAll: removeAllSubscriptionsMutation.isLoading,
    isSendingNotification: sendNotificationMutation.isLoading,
    isBroadcasting: broadcastNotificationMutation.isLoading,
    // Refetch functions
    refetchSubscribedUsers: () => subscribedUsersQuery.refetch(),
    refetchMySubscription: () => mySubscriptionQuery.refetch(),
    refetchPushNotificationsStatus: () => pushNotificationsStatusQuery.refetch()
  };
};