// src/components/NotificationSubscription.js
import React, { useState, useEffect } from 'react';
import { usePushNotifications } from '../react-query/usePushNotifications';

function NotificationSubscription({ styles }) {
  const [subscription, setSubscription] = useState(null);
  const [isSupported, setIsSupported] = useState(false);
  const [permissionState, setPermissionState] = useState('default');
  const [troubleshootVisible, setTroubleshootVisible] = useState(false);
  
  const { 
    subscribe, 
    unsubscribe, 
    isSubscribing, 
    isUnsubscribing 
  } = usePushNotifications();

  // Function to add a log to localStorage for the debugger
  const logEvent = (message, data = null) => {
    try {
      const logMessage = data ? `${message}: ${JSON.stringify(data)}` : message;
      console.log(`[PWA Notification] ${logMessage}`);
      
      const logItem = {
        timestamp: new Date().toISOString(),
        message: logMessage
      };
      
      // Add to localStorage for persistence
      const storedLogs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
      storedLogs.push(logItem);
      while (storedLogs.length > 50) storedLogs.shift();
      localStorage.setItem('pwa_notification_logs', JSON.stringify(storedLogs));
    } catch (e) {
      console.error('Error saving log:', e);
    }
  };

  // Handle messages from service worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const messageHandler = (event) => {
        if (event.data && event.data.type === 'SW_ACTIVATED') {
          logEvent('Received SW_ACTIVATED message from service worker');
          // Refresh the page to ensure everything is connected properly
          window.location.reload();
        }
      };
      
      navigator.serviceWorker.addEventListener('message', messageHandler);
      return () => {
        navigator.serviceWorker.removeEventListener('message', messageHandler);
      };
    }
  }, []);

  useEffect(() => {
    // Check if push notifications are supported
    const isPushSupported = 'serviceWorker' in navigator && 'PushManager' in window;
    setIsSupported(isPushSupported);
    
    // Log environment info
    const isIOSPWA = window.navigator.standalone === true;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    logEvent('Environment check', { isIOS, isIOSPWA, userAgent: navigator.userAgent });
    logEvent('Push support check', { supported: isPushSupported });

    if (isPushSupported) {
      // Check current notification permission
      setPermissionState(Notification.permission);
      logEvent('Current permission state', { permission: Notification.permission });
      
      // Check if already subscribed
      navigator.serviceWorker.getRegistrations().then(registrations => {
        logEvent('Service worker registrations', { count: registrations.length });
      });
      
      // Check if service worker is controlling the page
      logEvent('Service worker controller status', { 
        hasController: !!navigator.serviceWorker.controller 
      });
      
      // Show troubleshooting by default for iOS PWA
      if (isIOS && isIOSPWA) {
        setTroubleshootVisible(true);
      }
      
      navigator.serviceWorker.ready
        .then(registration => {
          logEvent('Service worker ready', { scope: registration.scope });
          return registration.pushManager.getSubscription();
        })
        .then(existingSubscription => {
          logEvent('Existing subscription check', { 
            exists: !!existingSubscription,
            endpoint: existingSubscription ? existingSubscription.endpoint : null
          });
          setSubscription(existingSubscription);
        })
        .catch(error => {
          logEvent('Error checking push subscription', { error: error.message });
          console.error('Error checking push subscription:', error);
        });
    }
  }, []);

  const handleSubscribe = async () => {
    logEvent('Subscribe button clicked');
    const result = await subscribe();
    logEvent('Subscribe result', { success: result.success });
    
    if (result.success && result.subscription) {
      setSubscription(result.subscription);
      logEvent('Subscription successful');
    } else {
      logEvent('Subscription failed', result);
      // Automatically show troubleshooting section on failure
      setTroubleshootVisible(true);
    }
  };

  const handleUnsubscribe = async () => {
    logEvent('Unsubscribe button clicked');
    if (!subscription) return;
    
    const result = await unsubscribe(subscription);
    logEvent('Unsubscribe result', { success: result.success });
    
    if (result.success) {
      setSubscription(null);
      logEvent('Unsubscription successful');
    }
  };

  // Function to reset everything and force registration
  const resetAndForceRegister = async () => {
    try {
      logEvent('Resetting service worker completely');
      
      // First unregister all service workers
      const registrations = await navigator.serviceWorker.getRegistrations();
      logEvent(`Unregistering ${registrations.length} service workers`);
      
      for (let registration of registrations) {
        await registration.unregister();
        logEvent(`Unregistered service worker with scope: ${registration.scope}`);
      }
      
      // Clear caches (may help with iOS issues)
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        logEvent(`Clearing ${cacheNames.length} caches`);
        
        for (let cacheName of cacheNames) {
          await caches.delete(cacheName);
          logEvent(`Deleted cache: ${cacheName}`);
        }
      }
      
      // Register a new service worker after a short delay
      setTimeout(async () => {
        try {
          logEvent('Registering fresh service worker');
          const registration = await navigator.serviceWorker.register('/service-worker.js');
          logEvent('Fresh registration successful', { scope: registration.scope });
          alert('Service worker reset successful! Please close and reopen the app from home screen, then try enabling notifications.');
        } catch (error) {
          logEvent('Fresh registration failed', { error: error.message });
          alert('Failed to register fresh service worker: ' + error.message);
        }
      }, 1000);
    } catch (error) {
      logEvent('Reset process failed', { error: error.message });
      alert('Failed to reset service worker: ' + error.message);
    }
  };
  
  // Function to force service worker registration without unregistering
  const forceRegisterSW = async () => {
    try {
      logEvent('Manually registering service worker');
      const registration = await navigator.serviceWorker.register('/service-worker.js');
      logEvent('Manual registration successful', { scope: registration.scope });
      alert('Service worker registered! Try enabling notifications now.');
    } catch (error) {
      logEvent('Manual registration failed', { error: error.message });
      alert('Failed to register service worker: ' + error.message);
    }
  };

  if (!isSupported) {
    return (
      <div className={styles?.notificationContainer || "notification-container"}>
        <p>Push notifications are not supported in this browser.</p>
      </div>
    );
  }

  return (
    <div className={styles?.notificationContainer || "notification-container"}>
      {permissionState === 'denied' ? (
        <div>
          <p>You have blocked notifications. Please enable them in your browser settings to receive updates.</p>
        </div>
      ) : subscription ? (
        <div>
          <p>✅ You are subscribed to notifications</p>
          <button 
            onClick={handleUnsubscribe}
            className={styles?.unsubscribeButton || "unsubscribe-button"}
            disabled={isUnsubscribing}
          >
            {isUnsubscribing ? 'Unsubscribing...' : 'Unsubscribe from notifications'}
          </button>
        </div>
      ) : (
        <div>
          <p>Stay updated with important alerts from P.A.C.E.</p>
          <button 
            onClick={handleSubscribe}
            className={styles?.subscribeButton || "subscribe-button"}
            disabled={isSubscribing}
          >
            {isSubscribing ? 'Enabling...' : 'Enable Notifications'}
          </button>
          
          <div style={{ marginTop: '10px' }}>
            <button 
              onClick={() => setTroubleshootVisible(!troubleshootVisible)}
              style={{
                background: 'none',
                border: 'none',
                color: '#007bff',
                textDecoration: 'underline',
                cursor: 'pointer',
                padding: 0,
                fontSize: '0.85rem'
              }}
            >
              {troubleshootVisible ? 'Hide troubleshooting' : 'Show troubleshooting'}
            </button>
          </div>
          
          {troubleshootVisible && (
            <div className={styles?.iosPwaNote || "ios-pwa-note"} style={{ marginTop: '10px', fontSize: '0.85rem', color: '#666', backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '5px' }}>
              <p><strong>iOS Troubleshooting Steps:</strong></p>
              <ol style={{ paddingLeft: '20px', marginTop: '5px' }}>
                <li>Try resetting the service worker first:
                  <button 
                    onClick={resetAndForceRegister} 
                    style={{
                      marginLeft: '10px',
                      backgroundColor: '#dc3545',
                      color: 'white',
                      border: 'none',
                      padding: '3px 8px',
                      borderRadius: '3px',
                      fontSize: '0.8rem',
                      cursor: 'pointer'
                    }}
                  >
                    Reset Service Worker
                  </button>
                </li>
                <li>Close the app completely (swipe up from app switcher)</li>
                <li>Reopen the app from the home screen icon</li>
                <li>Wait a few seconds for service worker to initialize</li>
                <li>Try enabling notifications again</li>
                <li>If still failing, try manual registration:
                  <button 
                    onClick={forceRegisterSW} 
                    style={{
                      marginLeft: '10px',
                      backgroundColor: '#007bff',
                      color: 'white',
                      border: 'none',
                      padding: '3px 8px',
                      borderRadius: '3px',
                      fontSize: '0.8rem',
                      cursor: 'pointer'
                    }}
                  >
                    Manual Registration
                  </button>
                </li>
              </ol>
              
              <p style={{ marginTop: '10px' }}><strong>iOS Compatibility Note:</strong></p>
              <p>Push notifications only work on iOS 16.4 or newer, and only when app is added to home screen and opened from there.</p>
            </div>
          )}
        </div>
      )}
      
      {/* For debugging purposes, add a small link to view debug logs */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <a 
          href="#debuglogs" 
          onClick={(e) => {
            e.preventDefault();
            const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
            alert(`Debug logs (${logs.length}):\n\n${logs.map(log => 
              `${new Date(log.timestamp).toLocaleTimeString()}: ${log.message}`
            ).join('\n\n')}`);
          }}
          style={{ fontSize: '0.7rem', color: '#999', textDecoration: 'none' }}
        >
          Debug Info
        </a>
      </div>
    </div>
  );
}

export default NotificationSubscription;