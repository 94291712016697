import React, { useEffect } from 'react';
import NotificationSubscription from '../components/NotificationSubscription';
import NotificationTester from '../components/NotificationTester';
import NotificationDebugger from '../components/NotificationDebugger';
import styles from '../stylez/NotificationPage.module.css';

function NotificationTestPage() {
  // Listen for messages from service worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const handleMessage = (event) => {
        // Handle SW_LOG messages to save in localStorage
        if (event.data && event.data.type === 'SW_LOG') {
          try {
            const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
            logs.push({
              timestamp: event.data.timestamp,
              message: `[SW Client] ${event.data.message}`
            });
            while (logs.length > 50) logs.shift();
            localStorage.setItem('pwa_notification_logs', JSON.stringify(logs));
          } catch (e) {
            console.error('Error saving SW log:', e);
          }
        }
      };

      // Add the message event listener
      navigator.serviceWorker.addEventListener('message', handleMessage);
      
      // Clean up
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage);
      };
    }
  }, []);
  
  return (
    <div className={styles.notificationPage}>
      <h1>Push Notification Testing</h1>
      
      <div className={styles.notificationSection}>
        <h2>Subscribe to Notifications</h2>
        <p>
          Enable push notifications to receive updates from P.A.C.E even when the app is closed.
          Since you're using this as a PWA on iOS, you'll need to add this app to your home screen
          for the best notification experience.
        </p>
        <NotificationSubscription styles={styles} />
      </div>
      
      <div className={styles.notificationSection}>
        <h2>Test Notifications</h2>
        <p>
          Once you've enabled notifications, you can use the button below to test receiving a notification.
          This will show a sample notification that you would receive when there's an update.
        </p>
        <NotificationTester styles={styles} />
      </div>
      
      <div className={styles.notificationInfo}>
        <h3>Important Notes for iOS PWA Users:</h3>
        <ul>
          <li>For optimal notifications, ensure you've added this app to your home screen</li>
          <li>iOS has some limitations with web push notifications compared to Android</li>
          <li>Notifications will only appear when the app is in the foreground on older iOS versions</li>
          <li>iOS 16.4+ supports web push notifications for PWA home screen apps</li>
          <li>You must launch the app from your home screen icon (not from Safari bookmarks)</li>
        </ul>
      </div>
      
      {/* Add the debugger component */}
      <NotificationDebugger />
    </div>
  );
}

export default NotificationTestPage;