import React, { useEffect, useState } from 'react';
import '../../stylez/EventList.css'
import EventCard from './EventCard';
import { useAuth } from '../../react-query/useAuth';
import { vi } from 'date-fns/locale';
import DeletePopup from '../ScheduleMakerComponents/RcurringTimeSlotRemovalPopup';

// EventList Component
function EventList({ events, view="Maker", dropClaimedEvent=() => {}, claimCoachEvent=() => {}}) {
    const { userData } = useAuth();
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [curElToBeDel, setCurElToBeDel] = useState(null);

    // Sort events by start time
    const sortedEvents = events.sort((a, b) => new Date(a.start) - new Date(b.start));

    const showPopup = (event) => {
        setCurrentEvent(event);
        setIsPopupVisible(true);
    };
      
    const hidePopup = () => {
        setIsPopupVisible(false);
        setCurrentEvent(null);
    };
      
    const unclaimEvent = (event) => {
        // grab the month and year form the event.start which is in iso format
        const month = new Date(event.start).getMonth() + 1;
        const year = new Date(event.start).getFullYear();
        console.log('Unclaiming event', month, year);
        console.log('Unclaiming event');
        dropClaimedEvent(event, month, year);
    }
    
    const claimEvent = (event) => {
        if (event._id && event.title != ''){
            console.log('already claimed')
            return
        } 
        console.log('Claiming event for ', userData.first_name);
        console.log('Claiming event', event);
        claimCoachEvent(event.type, event.recurring, event.start, event.end, userData.first_name, event._id ? event._id : null);
    }

    const handleDeleteClick = async (event) => {
        setCurElToBeDel(event);
        if (event.recurring){
            setShowDeletePopup(true);
            return
        } else {
            console.log('delete non recurring event')
            console.log(event)
            await dropClaimedEvent(event, false);
        }
    };

    const deleteEvent = (event, all=false) => {
        console.log('Deleting event');
        console.log(event);
    }

    const handleClosePopup = () => {
        setShowDeletePopup(false);
    };
    
    const handleDeleteOne = async () => {
        console.log('delete event one');
        await dropClaimedEvent(curElToBeDel, false);
        handleClosePopup();
    };

    const handleDeleteAll = async () => {
        console.log('delete event all');
        await dropClaimedEvent(curElToBeDel, true);
        handleClosePopup();
    };

    return (
        <div className="event-list" style={view === "Delete" ? {width:"260px"} :  null}>
            {sortedEvents.map((event) => (
                <EventCard
                    key={event._id || `event-${Math.random()}`}
                    event={event}
                    view={view}
                    onRemove={view === "Delete" ? () => deleteEvent(event, false) : () => unclaimEvent(event)}
                    onClaim={() => claimEvent(event)}
                    onShowPopup={showPopup}
                    onDeleteClick={view === "Delete" ? () => handleDeleteClick(event) : null}
                />
            ))}
            
            {/* Render deletion popup directly without modal-overlay div */}
            {showDeletePopup && (
                <DeletePopup
                    onClose={handleClosePopup}
                    onDeleteOne={handleDeleteOne}
                    onDeleteAll={handleDeleteAll}
                />
            )}
        </div>
    );
}

export default EventList;