import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaRunning, FaBed, FaCheckCircle, FaRegTimesCircle, FaBell, FaBellSlash } from 'react-icons/fa';
import styles from '../../stylez/AdminPage.module.css';
import ProfilePictureViewer from '../SettingsComponents/PFPViewer';
import default_pfp from '../../imgs/muscle_default.svg'
import { useEffect } from 'react';
import { fetchImage } from '../../services/api_data';

const UserRow = ({ user }) => {
    
    const [pfp, setPfp] = useState(default_pfp);

    useEffect(() => {
        const getProfilePicture = async () => {
          try {
            const response = await fetchImage(user.image_url);
            setPfp(response);
          } catch (error) {
            console.error('An error occurred while fetching the profile picture:', error);
          }
        }
    
        if (user.image_url) {
          console.log(user.first_name);
          console.log("Fetching profile picture");
          getProfilePicture();
        }
      }, [user.image_url]);

    // Custom class based on active status
    const rowClassName = `${styles.userRow} ${user.isActive ? styles.userRowActive : ''}`;

    return (
    <motion.div 
      className={rowClassName}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      whileHover={{ 
        scale: 1.01,
        transition: { duration: 0.2 }
      }}
    >
      <div className={styles.profilePicPlaceholder}>
        <ProfilePictureViewer src={pfp} size={40} alt={user.first_name} />
      </div>
      <div className={styles.userName}>
        {user.first_name} {user.last_name}
      </div>
      <div className={styles.userStatus}>
        <motion.div 
          className={styles.statusIcon}
          whileHover={{ scale: 1.2 }}
          title={user.isActive ? "User is active" : "User is inactive"}
        >
          {user.isActive ? <FaRunning size={20} color="#27ae60" /> : <FaBed size={20} color="#e74c3c" />}
        </motion.div>
        
        <motion.div 
          className={styles.statusIcon}
          whileHover={{ scale: 1.2 }}
          title={user.welcomed ? "User has been welcomed" : "User has not been welcomed"}
        >
          {user.welcomed ? <FaCheckCircle size={20} color="#27ae60" /> : <FaRegTimesCircle size={20} color="#e74c3c" />}
        </motion.div>
        
        <motion.div 
          className={styles.statusIcon}
          whileHover={{ scale: 1.2 }}
          title={user.isSubscribedToNotifications ? "Subscribed to notifications" : "Not subscribed to notifications"}
        >
          {user.isSubscribedToNotifications ? (
            <FaBell size={20} color="#27ae60" />
          ) : (
            <FaBellSlash size={20} color="#7f8c8d" />
          )}
        </motion.div>
      </div>
    </motion.div>
  );
}
  

export default UserRow;