import { useMutation, useQueryClient } from 'react-query';
import { uploadFile, updateDailyVideo } from '../services/api_data';
import { toast } from 'react-toastify';


const useProgUpload = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(uploadFile, {
        onSuccess: () => {
            // queryClient.invalidateQueries('files');
            toast.success('Programming uploaded successfully');
        }, 
        onError: (error) => {
            toast.error('Error uploading programming', error.message);
            console.error('Error uploading file:', error);
        }
    });


    // return useMutation(uploadFile);
    return mutation;

};

export const useDailyVideoUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation(updateDailyVideo, {
        onSuccess: () => {
            queryClient.invalidateQueries('daily-video');
            toast.success('Daily video updated successfully');
        },
        onError: (error) => {
            toast.error(`Error updating daily video: ${error.message}`);
            console.error('Error updating daily video:', error);
        }
    });
};

export default useProgUpload;