import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../../stylez/UserDetailCard.css';
import ProfilePictureViewer from '../SettingsComponents/PFPViewer';
import { fetchImage } from '../../services/api_data';
import default_pfp from '../../imgs/muscle_default.svg'
import { useAdmin } from '../../react-query/useAdmin';

const UserDetailCard = ({coach}) => {
  const [isAdmin, setIsAdmin] = useState(coach.is_admin);
  const [pfp, setPfp] = useState(default_pfp);
  const { updateUserAdminStatus } = useAdmin();

  const toggleAdminStatus = () => {
    const previousAdminStatus = isAdmin;

    setIsAdmin(!isAdmin);
    // Attempt to update the admin status
    updateUserAdminStatus({
      userId: coach.id,
      isAdmin: !isAdmin,
    }, {
      // If there's an error, revert the admin status back to the original
      onError: () => setIsAdmin(previousAdminStatus),
    });
  };
  
  useEffect(() => {
    const getProfilePicture = async () => {
      try {
        const response = await fetchImage(coach.image_url);
        setPfp(response);
      } catch (error) {
        console.error('An error occurred while fetching the profile picture:', error);
      }
    }

    if (coach.image_url) {
      console.log(coach.first_name);
      console.log("Fetching profile picture");
      getProfilePicture();
    }
  }, [coach]);

  return (
    <motion.div 
      className="user-detail-card"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      whileHover={{ y: -5 }}
    >
      <motion.div 
        className="profile-section"
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <ProfilePictureViewer src={pfp} size={100} alt="Coach Profile Picture" />
        <motion.div 
          className="name"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          {coach.first_name} {coach.last_name}
        </motion.div>
      </motion.div>
      <motion.div 
        className="admin-toggle"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
        <label className="switch">
          <input type="checkbox" checked={isAdmin} onChange={toggleAdminStatus} />
          <span className="slider round"></span>
        </label>
        <div className="status-label">{isAdmin ? 'Admin' : 'Not Admin'}</div>
      </motion.div>
    </motion.div>
  );
};

export default UserDetailCard;