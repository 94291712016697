import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import BiWeeklyToolBar from "../components/HoursComponents/BiWeeklyToolBar";
import HoursWorkedCardsList from "../components/HoursComponents/HoursWorkedCardList";
import { useEvents } from "../react-query/useEvents";
import { addDays, differenceInDays, format } from "date-fns";
import TotalHoursCounter from "../components/HoursComponents/TotalHoursCounter";
import "../stylez/HoursWorkedPage.css";

const HoursWorked = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { events, isLoading, isError, error } = useEvents({ 
        startDate: startDate, 
        endDate: endDate, 
        fetchType: 'biweekly' 
    });
    
    // An arbitrary "epoch" start date
    const epochStartDate = new Date(2022, 9, 17); // Remember, months are 0-indexed in JavaScript Date objects
    
    const formatWeek = (date) => {
        if (!date || !(date instanceof Date)) return ''; 
        
        // Calculate days since epoch
        const daysSinceEpoch = differenceInDays(date, epochStartDate);
        
        // Calculate the current two-week period's start date
        const currentPeriodStart = addDays(epochStartDate, Math.floor(daysSinceEpoch / 14) * 14);
        const currentPeriodEnd = addDays(currentPeriodStart, 13);
        
        return {
            startDate: format(currentPeriodStart, 'MM/dd/yyyy'), 
            endDate: format(currentPeriodEnd, 'MM/dd/yyyy')
        };
    };

    useEffect(() => {
        const {startDate, endDate} = formatWeek(currentDate);
        setStartDate(startDate);
        setEndDate(endDate);
    }, [currentDate]);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { 
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    return ( 
        <motion.div 
            className="hours-worked-page"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            <div className="hours-worked-container">
                <BiWeeklyToolBar 
                    currentDate={currentDate} 
                    setCurrentDate={setCurrentDate} 
                    startDate={startDate} 
                    endDate={endDate} 
                />
                
                {isLoading ? (
                    <div className="loading-spinner">
                        <div className="spinner"></div>
                        <p>Loading hours...</p>
                    </div>
                ) : isError ? (
                    <div className="error-message">
                        <p>Failed to load hours data. Please try again.</p>
                    </div>
                ) : (
                    <>
                        <TotalHoursCounter events={events} />
                        <HoursWorkedCardsList events={events} />
                    </>
                )}
            </div>
        </motion.div>
    );
}
 
export default HoursWorked;