import React from 'react';
import { FaHourglassHalf } from 'react-icons/fa'; 
import '../../stylez/DayWorkedCard.css'

function CoachHoursCard({ coachName, eventCount }) {

  
  return (
    <div className="day-worked-card">
      <FaHourglassHalf className="hourglass-card-icon" />
      <div className="divider"></div>
      <div className="hours-content">
        <h3>{coachName}</h3>
        <p>{eventCount} Hr</p>
      </div>
    </div>
  );
}

export default CoachHoursCard;
