import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import UserRow from '../components/AdminComponents/UserRow';
import { useAdmin } from '../react-query/useAdmin';
import { useQueryClient, useQuery } from 'react-query';
import styles from '../stylez/AdminPage.module.css';
import UserDetailCard from '../components/AdminComponents/UserDetail';
import { getSubscribedUsers } from '../services/api_push';

const UsersPage = () => {
    const { users, isLoading, isError, error } = useAdmin();
    const [webSocket, setWebSocket] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const queryClient = useQueryClient();
    const [usersWithSubscriptions, setUsersWithSubscriptions] = useState([]);

    // Fetch subscribed users
    const { data: subscribedUsers, isLoading: isLoadingSubscriptions } = useQuery(
        'subscribedUsers',
        getSubscribedUsers,
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                console.log('Subscribed users loaded:', data);
            },
            onError: (error) => {
                console.error('Error loading subscribed users:', error);
            }
        }
    );
    
    // Merge users with subscription data
    useEffect(() => {
        if (users && subscribedUsers) {
            // Create a Set of subscribed user IDs for O(1) lookup
            const subscribedUserIds = new Set(subscribedUsers.map(user => user.user_id));
            
            // Merge the data
            const merged = users.map(user => ({
                ...user,
                isSubscribedToNotifications: subscribedUserIds.has(user.id)
            }));
            
            setUsersWithSubscriptions(merged);
        }
    }, [users, subscribedUsers]);
    
    const getStoredToken = () => {
        return localStorage.getItem('accessToken'); // or however you store your token
    };

useEffect(() => {
    // Initialize WebSocket connection
    const ws = new WebSocket('wss://coachify-api.graytecknologies.com/api/admin/ws');
    // const ws = new WebSocket('wss://localhost:8000/api/admin/ws');
    setWebSocket(ws);

    // Handle WebSocket events
    ws.onopen = () => {
        console.log('WebSocket connected');
        // Send the token as the first message
        const token = getStoredToken();
        if (token) {
            ws.send(JSON.stringify({ token }));
        } else {
            console.error('Access token not found');
            ws.close(); // Close the connection if there's no token
        }
    };

    ws.onmessage = (event) => {
        console.log('Received message:', event.data);
        const message = JSON.parse(event.data); // Parse the incoming message string into a JavaScript object
        
        if (message.message === "user_status_update") {
            // Assuming 'users' is the queryKey for the admin user list
            queryClient.setQueryData(['Users'], (oldQueryData) => {
            // Update the specific user's data with the new status
            return oldQueryData.map((user) => {
                if (user.id === message.user_id) {
                // Only update 'welcomed' if it's included in the message
                const updatedUser = { ...user, isActive: message.isActive };
                if ('welcomed' in message) {
                    updatedUser.welcomed = message.welcomed;
                }
                return updatedUser;
                }
                return user;
            });
            });
        }
        };

    ws.onerror = (error) => {
        console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
        console.log('WebSocket disconnected');
    };

    // Clean up WebSocket connection when the hook is no longer used
    return () => {
        if (ws) {
        ws.close();
        }
        setWebSocket(null);
    };
    // }, [queryClient]);
}, []);


    // console.log(data);
    console.log(users);

    if (isLoading || isLoadingSubscriptions) {
        return (
            <div className="loading-container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <div className="loading-spinner" style={{width: '50px', height: '50px', border: '5px solid #f3f3f3', borderTop: '5px solid #3498db', borderRadius: '50%', animation: 'spin 1s linear infinite'}}></div>
                <style>{`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}</style>
            </div>
        );
    }

   // Function to handle user row click
   const handleUserClick = (user) => {
       setSelectedUser(user);
   };

   // Function to go back to the users list
   const handleBackClick = () => {
       setSelectedUser(null);
   };

   // Stagger animation variants
   const containerVariants = {
       hidden: { opacity: 0 },
       visible: {
           opacity: 1,
           transition: {
               staggerChildren: 0.07
           }
       },
       exit: { 
           opacity: 0,
           transition: { duration: 0.2 }
       }
   };

   if (selectedUser) {
       // Render user detail page
       return (
           <motion.div 
               className="user-detail-container"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               exit={{ opacity: 0 }}
           >
                <motion.h1 
                    className={styles.pageTitle}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    User Details
                </motion.h1>
                <UserDetailCard coach={selectedUser} />
                <motion.button 
                    className="back-button" 
                    onClick={handleBackClick}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    Back to users
                </motion.button>
            </motion.div>
       );
   }

   // Render users list
   return (
       <div style={{background:"#f4f7f6", paddingTop:"20px", minHeight: "100vh"}}>
           <motion.h1 
               className={styles.pageTitle}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 0.3 }}
           >
               All Users
           </motion.h1>
           
           <motion.div 
               className={styles.userListContainer}
               variants={containerVariants}
               initial="hidden"
               animate="visible"
               exit="exit"
           >
                <AnimatePresence>
                    {usersWithSubscriptions.map((user, index) => (
                        <div onClick={() => handleUserClick(user)} key={user.id || index}>
                            <UserRow user={user} />
                        </div>
                    ))}
                </AnimatePresence>
           </motion.div>
       </div>
   );
}

export default UsersPage;