import React, { useEffect } from 'react';
import '../../stylez/Popup.css';
import { MdClose, MdDeleteForever, MdDeleteSweep } from 'react-icons/md';

const DeletePopup = ({ onClose, onDeleteOne, onDeleteAll }) => {
    
    useEffect(() => {
        // Handle escape key press
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
    
        document.addEventListener('keydown', handleEscapeKey);
    
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);
    
    return (
        <div className="popup-background">
            {/* Removed the delete-popup class which might be causing positioning issues */}
            <div className="popup-container">
                <div className="popup-header">
                    <h3>Delete Recurring Class</h3>
                    <button className="close-icon" onClick={onClose}>
                        <MdClose size={24} />
                    </button>
                </div>
                
                <div className="popup-content">
                    <p>Would you like to delete this class only or all recurring instances of this class?</p>
                    
                    <div className="popup-actions">
                        <button 
                            className="popup-button delete-one" 
                            onClick={onDeleteOne}
                        >
                            <MdDeleteForever size={20} />
                            <span>Delete This Class</span>
                        </button>
                        
                        <button 
                            className="popup-button delete-all" 
                            onClick={onDeleteAll}
                        >
                            <MdDeleteSweep size={20} />
                            <span>Delete All Classes</span>
                        </button>
                        
                        <button 
                            className="popup-button cancel" 
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeletePopup;