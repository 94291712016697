// AuthDebug.js - Helper functions for debugging authentication issues in the PWA

/**
 * Logs authentication-related debug information to localStorage for viewing in the debug page
 * @param {string} message - The debug message
 * @param {Object} data - Optional data to include with the log
 */
export const logAuthDebug = (message, data = null) => {
  try {
    const timestamp = new Date().toISOString();
    const logEntry = {
      timestamp,
      message,
      data: data ? JSON.stringify(data) : null
    };

    // Store in localStorage
    const existingLogs = JSON.parse(localStorage.getItem('auth_debug_logs') || '[]');
    existingLogs.unshift(logEntry); // Add to beginning for chronological display

    // Keep only last 50 entries to prevent storage issues
    while (existingLogs.length > 50) {
      existingLogs.pop();
    }

    localStorage.setItem('auth_debug_logs', JSON.stringify(existingLogs));
    
    // Also log to console
    console.log(`[Auth Debug] ${message}`, data || '');
    
    // Add to PWA notification logs for visibility on debug page
    try {
      const pwaLogs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
      pwaLogs.push({
        timestamp,
        message: `[Auth] ${message}${data ? ': ' + JSON.stringify(data) : ''}`
      });
      // Keep only last 50 logs
      while (pwaLogs.length > 50) pwaLogs.shift();
      localStorage.setItem('pwa_notification_logs', JSON.stringify(pwaLogs));
    } catch (e) {
      console.error('Error adding to PWA logs:', e);
    }
  } catch (err) {
    console.error('Error saving auth debug log:', err);
  }
};

/**
 * Clear auth debug logs from localStorage
 */
export const clearAuthDebugLogs = () => {
  localStorage.removeItem('auth_debug_logs');
};

/**
 * Get all auth debug logs from localStorage
 * @returns {Array} Array of log entries
 */
export const getAuthDebugLogs = () => {
  try {
    return JSON.parse(localStorage.getItem('auth_debug_logs') || '[]');
  } catch (err) {
    console.error('Error reading auth debug logs:', err);
    return [];
  }
};