import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
    {/* Add the React Query Devtools component */}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Register the service worker for PWA support with customized configuration
// This enables push notifications functionality
serviceWorkerRegistration.register({
  // When there's a new version of the service worker:
  onUpdate: registration => {
    console.log('New service worker available, updating...');
    // Optional: Add code to notify the user of updates
  },
  // When the service worker is installed for the first time:
  onSuccess: registration => {
    console.log('Service worker registered successfully!');
    // You can add additional initialization code here
  }
});