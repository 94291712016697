// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// Helper function to log events to localStorage for iOS debugging
const logToStorage = (msg, data = null) => {
  try {
    const message = data ? `${msg}: ${JSON.stringify(data)}` : msg;
    console.log(`[SW Registration] ${message}`);
    
    const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
    logs.push({
      timestamp: new Date().toISOString(),
      message: `[SW] ${message}`
    });
    // Keep only last 50 logs
    while (logs.length > 50) logs.shift();
    localStorage.setItem('pwa_notification_logs', JSON.stringify(logs));
  } catch (e) {
    console.error('Error logging:', e);
  }
};

// Check if we're in a PWA environment running on iOS
const isIOSPWA = () => 
  window.navigator.standalone === true || // iOS Safari
  window.matchMedia('(display-mode: standalone)').matches; // Other browsers

const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export function register(config) {
  // Allow service worker registration even in development for testing purposes
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      
      logToStorage('Service worker registration starting', { 
        isIOS: isIOS(), 
        isIOSPWA: isIOSPWA(), 
        url: swUrl 
      });

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          logToStorage('Service worker ready on localhost');
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  logToStorage('Registering service worker', { url: swUrl });
  
  // Check for iOS PWA
  const isiOSPWA = isIOSPWA();
  const isiOS = isIOS();
  
  // For iOS PWA, try with both possible scopes
  if (isiOS && isiOSPWA) {
    logToStorage('iOS PWA detected, using special registration strategy');
    
    // First try registering with the current scope
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        handleRegistration(registration, config, true);
      })
      .catch((error) => {
        logToStorage('Error during main scope registration', { error: error.toString() });
        console.error('Error during service worker registration:', error);
      });
  } else {
    // Standard registration for non-iOS
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        handleRegistration(registration, config, false);
      })
      .catch((error) => {
        logToStorage('Error during service worker registration', { error: error.toString() });
        console.error('Error during service worker registration:', error);
      });
  }
}

function handleRegistration(registration, config, isIOSPwaEnv) {
  logToStorage('Service worker registered with scope', { 
    scope: registration.scope,
    isIOSPwaEnv
  });
  
  // Immediately claim clients to gain control
  if (registration.active) {
    registration.active.postMessage({ type: 'CLAIM_CLIENTS' });
    logToStorage('Sent CLAIM_CLIENTS message to active worker');
  }
  
  // For iOS PWA, set up interval to repeatedly try to claim clients
  if (isIOSPwaEnv) {
    const claimAttempts = 5; // try 5 times
    let attemptCount = 0;
    
    const claimInterval = setInterval(() => {
      attemptCount++;
      if (registration.active) {
        registration.active.postMessage({ 
          type: 'CLAIM_CLIENTS',
          force: true
        });
        logToStorage(`Sent CLAIM_CLIENTS message (attempt ${attemptCount}/${claimAttempts})`);
      } else {
        logToStorage(`No active worker for claim attempt ${attemptCount}/${claimAttempts}`);
      }
      
      if (navigator.serviceWorker.controller) {
        logToStorage('Controller detected, stopping claim attempts');
        clearInterval(claimInterval);
      }
      
      if (attemptCount >= claimAttempts) {
        logToStorage('Max claim attempts reached, stopping interval');
        clearInterval(claimInterval);
      }
    }, 1000); // Try every second
  }
  
  registration.onupdatefound = () => {
    const installingWorker = registration.installing;
    if (installingWorker == null) {
      logToStorage('No installing worker found in onupdatefound');
      return;
    }
    
    logToStorage('Service worker update found, current state', { 
      state: installingWorker.state
    });
    
    installingWorker.onstatechange = () => {
      logToStorage('Service worker state changed', { 
        state: installingWorker.state,
        hasController: !!navigator.serviceWorker.controller
      });
      
      if (installingWorker.state === 'installed') {
        if (navigator.serviceWorker.controller) {
          // At this point, the updated precached content has been fetched,
          // but the previous service worker will still serve the older
          // content until all client tabs are closed.
          logToStorage('New content available, waiting for tabs to close');

          // Execute callback
          if (config && config.onUpdate) {
            config.onUpdate(registration);
          }
        } else {
          // At this point, everything has been precached.
          logToStorage('Content cached for offline use');

          // Execute callback
          if (config && config.onSuccess) {
            config.onSuccess(registration);
          }
          
          // Special handling for iOS PWA
          if (isIOSPwaEnv) {
            logToStorage('iOS PWA: Force claiming clients without reload');
            
            // For iOS, try to skip the reload and force claim clients
            if (registration.active) {
              registration.active.postMessage({ 
                type: 'CLAIM_CLIENTS', 
                force: true 
              });
            }
          } else {
            // For non-iOS, reload is generally safe
            logToStorage('Reloading page to ensure service worker takes control');
            
            // Wait a moment before reloading to allow service worker to initialize
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      }
    };
  };
  
  // Handle push subscription
  if ('PushManager' in window) {
    logToStorage('Push messaging is supported');
    registration.pushManager.getSubscription()
      .then(subscription => {
        if (!subscription) {
          logToStorage('Not subscribed to push notifications');
        } else {
          logToStorage('Existing push subscription found', { 
            endpoint: subscription.endpoint 
          });
        }
      })
      .catch(error => {
        logToStorage('Error checking push subscription', { 
          error: error.toString() 
        });
      });
  }
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  logToStorage('Checking for valid service worker', { url: swUrl });
  
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        logToStorage('Invalid service worker response', { 
          status: response.status,
          contentType 
        });
        
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        logToStorage('Valid service worker found, proceeding with registration');
        registerValidSW(swUrl, config);
      }
    })
    .catch((error) => {
      logToStorage('Fetch check failed, running in offline mode', { 
        error: error.toString() 
      });
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

// Helper function to subscribe to push notifications
export async function subscribeToPushNotifications() {
  if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
    logToStorage('Push notifications not supported');
    return false;
  }
  
  try {
    // First, check current permission explicitly 
    const currentPermission = Notification.permission;
    logToStorage('Current notification permission before asking', { permission: currentPermission });
    
    // For iOS, we need to make sure permission is granted BEFORE proceeding
    // If not already granted, ask for permission
    if (currentPermission !== 'granted') {
      logToStorage('No permission yet, requesting permission');
      const permissionResult = await Notification.requestPermission();
      logToStorage('Permission request result', { permission: permissionResult });
      
      if (permissionResult !== 'granted') {
        logToStorage('Permission denied by user');
        return false;
      }
      
      // For iOS Safari, add a delay after permission is granted
      if (isIOS()) {
        logToStorage('iOS detected, adding permission settlement delay');
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
    
    // Now that we have permission, get the service worker registration
    logToStorage('Permission granted, getting service worker registration');
    const registration = await navigator.serviceWorker.ready;
    logToStorage('Service worker ready for push subscription', { 
      scope: registration.scope 
    });
    
    // Double-check current existing subscription
    const existingSubscription = await registration.pushManager.getSubscription();
    if (existingSubscription) {
      logToStorage('Found existing subscription, returning it', {
        endpoint: existingSubscription.endpoint
      });
      return existingSubscription;
    }
    
    // For iOS Safari compatibility, add a small delay before subscribing
    // This helps ensure the permission is fully processed
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Subscribe user with VAPID key
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        // Your VAPID public key
        'BDVrtyNsLK0NxeM7SA1jmRlcHrt6bB--1z7Lg61TeiTGNqjDtRdPGEllpWef54G_zxM-hOmE-wSaKGWHhsWAqBQ'
      )
    };
    
    logToStorage('Attempting to subscribe to push notifications');
    
    // Try up to 3 times to subscribe
    let subscription = null;
    let attempts = 0;
    const maxAttempts = 3;
    
    while (!subscription && attempts < maxAttempts) {
      attempts++;
      try {
        logToStorage(`Subscription attempt ${attempts}/${maxAttempts}`);
        subscription = await registration.pushManager.subscribe(subscribeOptions);
        logToStorage('Push subscription successful', { 
          endpoint: subscription.endpoint 
        });
      } catch (subError) {
        logToStorage(`Subscription attempt ${attempts} failed`, { error: subError.toString() });
        
        if (attempts >= maxAttempts) {
          throw subError; // Re-throw if this was our last attempt
        }
        
        // Wait a bit longer before next attempt
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
    
    return subscription;
  } catch (error) {
    logToStorage('Failed to subscribe to push notifications', { 
      error: error.toString(),
      errorStack: error.stack
    });
    console.error('Failed to subscribe to push notifications:', error);
    return false;
  }
}

// Helper function to convert base64 string to Uint8Array
// (Required for applicationServerKey)
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}