import { authInstance, dataInstance } from './axiosInstance';
import { useHistory } from 'react-router-dom'; // Import useHistory from react-router-dom
import { toast } from 'react-toastify';
import { storeAuthDataInIndexedDB, clearAuthDataFromIndexedDB, getAuthDataFromIndexedDB } from './indexedDBService';
import { logAuthDebug } from '../functions/AuthDebug';

export const signin = async ({ email, password }) => {
    try {
      const formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);
      formData.append("grant_type", "password");  // Add your grant_type here
  
      // const response = await authInstance.post('https://coachify-api.graytecknologies.com/api/auth/signin', formData, {
      const response = await authInstance.post('/signin', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'  // Important when you're using FormData
        }
      });
  
      if (response.status === 200 && response.data.access_token) {
        // Store in both localStorage and IndexedDB for persistence
        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('userData', JSON.stringify(response.data.user));
        await storeAuthDataInIndexedDB(response.data.access_token, response.data.user);
        return response.data;
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  };

  export const signup = async ({email, password, firstName, lastName, userType}) => {
    try {
        const userData = {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            type: "coach"  // Assuming the server expects this in the request body
        };

        const response = await authInstance.post('/signup', userData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200 && response.data.access_token) {
            // Store in both localStorage and IndexedDB for persistence
            localStorage.setItem('accessToken', response.data.access_token);
            localStorage.setItem('userData', JSON.stringify(response.data.user));
            await storeAuthDataInIndexedDB(response.data.access_token, response.data.user);
            return response.data;
        }
        return null;
    } catch (error) {
        // Handle error
        console.log(error)
        throw error
    }
};

  

  export const signout = async () => {
    try {
      const response = await authInstance.post('/signout');
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('userData');
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      // Handle error
      // console.log('api_auth.js signout error', error)
      return false;
    }finally{
      // Clear both localStorage and IndexedDB
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');
      await clearAuthDataFromIndexedDB();
    }
  };



export const updateUserPassword = async ({ oldPassword, newPassword }) => {
  try {
      const passwordData = {
          current_password: oldPassword,
          new_password: newPassword
      };

      const response = await authInstance.post('/change-password', passwordData, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` // Assuming you store the token in local storage
          }
      });

      if (response.status === 200) {
          // toast.success(response.data.message || 'Password updated successfully');
          return response.data;
      } else {
          throw new Error('Failed to update password');
      }
  } catch (error) {
      console.error('Error updating password:', error);
      // toast.error(error.response?.data?.detail || 'Failed to update password.');
      throw error;
  }
};


  export const updateUser = async (updateData) => {
    try {
      // Filter updateData to include only non-empty string values
      const filteredUpdateData = Object.entries(updateData).reduce(
        (acc, [key, value]) => {
          if (value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      // Send PATCH request to update user details
      const response = await dataInstance.patch('/auth/update-details', filteredUpdateData);
      
      localStorage.setItem('userData', JSON.stringify(response.data.user));  // Assuming server returns user data
      // return response.data;
      // }
      
      
      if (response.status === 200 && response.data.access_token) {
        // Assuming that the server returns updated user data
        const updatedUserData = response.data;
        
      localStorage.setItem('accessToken', updatedUserData.access_token);
        // Also update in IndexedDB
        const userData = JSON.parse(localStorage.getItem('userData'));
        await storeAuthDataInIndexedDB(updatedUserData.access_token, userData);
        
        return updatedUserData;
      }
      return null;
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  };

  export const sendPfp = async (formData) => {
    try {
        const response = await dataInstance.post('/auth/upload-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            // Assuming the server returns the updated user data with the new image_url
            const updatedUserData = response.data;
            
            // Update the image_url in localStorage
            const storedUserData = JSON.parse(localStorage.getItem('userData'));
            storedUserData.image_url = updatedUserData.image_url;
            localStorage.setItem('userData', JSON.stringify(storedUserData));
            
            // Also update in IndexedDB
            const token = localStorage.getItem('accessToken');
            await storeAuthDataInIndexedDB(token, storedUserData);

            return response.data;
        }
        return null;
    } catch (error) {
        // Handle error
        console.error('Failed to upload profile picture:', error);
        return null;
    }
};

export const verifyAdmin = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await authInstance.get('/admin/verify', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      return 'valid';
    } else {
      return 'invalid';
    }
  } catch (error) {
    if (error.response) {
      return 'invalid';
    } else {
      return 'invalid';
    }
  }
};

// Verify if the token is still valid
export const verifyToken = async () => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return false;
    }
    
    try {
      // Instead of trying the non-existent verify-token endpoint,
      // use a lightweight authenticated endpoint that actually exists in your API
      // This could be a user profile endpoint or any authenticated route
      const response = await authInstance.get('/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Replace with an endpoint that exists in your API
      return response.status === 200;
    } catch (error) {
      if (error.response?.status === 404) {
        console.log('Endpoint not found, trying an alternative verification method');
        // Try another light endpoint if the first one doesn't exist
        try {
          // You can try your admin verification endpoint as a backup
          const adminCheckResponse = await authInstance.get('/admin/verify', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return adminCheckResponse.status === 200;
        } catch (innerError) {
          // Token is likely invalid if we get here
          console.log('Alternative token verification failed');
          return false;
        }
      }
      // If we got a 401 Unauthorized, token is definitely invalid
      if (error.response?.status === 401) {
        console.log('Token is invalid (401 response)');
        return false;
      }
      console.log('Token verification failed with error:', error?.message);
      return false;
    }
  } catch (error) {
    console.error('Token verification outer error:', error);
    return false;
  }
};

export const refreshToken = async () => {
  try {
    logAuthDebug('Refresh token process started', { platform: 'iOS PWA', timestamp: new Date().toISOString() });
    // Make sure to include credentials to send cookies
    logAuthDebug('Preparing refresh token request', { withCredentials: true });
    const response = await authInstance.post('/refresh', {}, {
      withCredentials: true // Ensure cookies are sent
    });
    
    logAuthDebug('Refresh token response received', { 
      status: response.status, 
      statusText: response.statusText,
      hasAccessToken: !!response.data.access_token,
      headers: Object.keys(response.headers || {})
    });
    
    if (response.status === 200 && response.data.access_token) {
      logAuthDebug('Token refresh successful', { tokenLength: response.data.access_token.length });
      // Save new access token to both localStorage and IndexedDB
      localStorage.setItem('accessToken', response.data.access_token);
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        logAuthDebug('Storing refreshed token in IndexedDB', { userId: userData.id });
        await storeAuthDataInIndexedDB(response.data.access_token, userData);
        logAuthDebug('IndexedDB storage completed');
      } else {
        logAuthDebug('No user data found in localStorage, skipping IndexedDB storage');
      }
      
      return response.data.access_token;
    }
    logAuthDebug('Token refresh response did not contain access_token', response.data);
    return null;
  } catch (error) {
    logAuthDebug('Token refresh error occurred', { 
      errorType: error.name, 
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText
    });
    
    // Check for specific refresh token errors
    if (error.response?.status === 401) {
      const refreshExpired = error.response.headers?.['x-refresh-expired'] === 'True';
      const refreshLost = error.response.headers?.['x-refresh-lost'] === 'True';
      const refreshError = error.response.headers?.['x-refresh-error'] === 'True';
      
      logAuthDebug('Analyzing 401 error headers', {
        refreshExpired,
        refreshLost,
        refreshError,
        allHeaders: Object.keys(error.response.headers || {})
      });
      
      if (refreshExpired || refreshLost || refreshError) {
        logAuthDebug('Refresh token issue detected, logging out user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userData');
        await clearAuthDataFromIndexedDB();
        logAuthDebug('Auth data cleared, redirecting to signin page');
        window.location.href = '/signin';
      } else {
        logAuthDebug('Auth failed but not due to refresh token issues');
      }
    }
    // Add debugging before returning
    // Additional debugging for iOS PWA
    const refreshDebugInfo = {
      timestamp: new Date().toISOString(),
      action: "refreshToken result",
      tokenState: localStorage.getItem("accessToken") ? "present" : "missing",
      userDataState: localStorage.getItem("userData") ? "present" : "missing"
    };

    // Add to PWA notification logs for visibility on debug page
    try {
      const refreshLogs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
      refreshLogs.push({
        timestamp: new Date().toISOString(),
        message: `[Auth Debug] ${JSON.stringify(refreshDebugInfo)}`
      });
      // Keep only last 50 logs
      while (refreshLogs.length > 50) refreshLogs.shift();
      localStorage.setItem("pwa_notification_logs", JSON.stringify(refreshLogs));
    } catch (e) {
      console.error("Error logging refresh debug:", e);
    }

    return null;
  }
};

// Restore authentication from IndexedDB if localStorage is missing
export const restoreAuthFromIndexedDB = async () => {
  logAuthDebug('Starting restoration from IndexedDB', { platform: 'iOS PWA', timestamp: new Date().toISOString() });
  const accessToken = localStorage.getItem('accessToken');
  const userData = localStorage.getItem('userData');
  
  logAuthDebug('Checking localStorage state', { 
    hasAccessToken: !!accessToken, 
    hasUserData: !!userData 
  });
  
  // If localStorage has auth data, we're good
  if (accessToken && userData) {
    logAuthDebug('Authentication data found in localStorage, no restoration needed');
    return true;
  }
  
  // Try to restore from IndexedDB
  try {
    logAuthDebug('Attempting to restore from IndexedDB');
    const authData = await getAuthDataFromIndexedDB();
    logAuthDebug('IndexedDB data retrieved', { 
      success: !!authData, 
      hasToken: authData?.token ? true : false,
      dataSize: authData ? JSON.stringify(authData).length : 0
    });
    
    if (authData && authData.token) {
      // Restore to localStorage
      logAuthDebug('Restoring data to localStorage', { tokenLength: authData.token.length });
      localStorage.setItem('accessToken', authData.token);
      localStorage.setItem('userData', JSON.stringify(authData.userData));
      
      // Verify token is still valid (can be done asynchronously)
      logAuthDebug('Initiating token verification process');
      verifyToken().then(isValid => {
        logAuthDebug('Token verification completed', { isValid });
        if (!isValid) {
          // If token is invalid, try to refresh it
          logAuthDebug('Token invalid, attempting refresh');
          refreshToken().catch((refreshError) => {
            // If refresh fails, clear everything and redirect to login
            logAuthDebug('Refresh failed, clearing auth data', { error: refreshError.message });
            localStorage.removeItem('accessToken');
            localStorage.removeItem('userData');
            clearAuthDataFromIndexedDB();
            logAuthDebug('Redirecting to sign in page');
            window.location.href = '/signin';
          });
        }
      });
      
      logAuthDebug('Auth restoration from IndexedDB successful');
      return true;
    } else {
      logAuthDebug('No valid authentication data found in IndexedDB');
    }
  } catch (error) {
    logAuthDebug('Error restoring auth from IndexedDB', { 
      errorType: error.name,
      message: error.message,
      stack: error.stack
    });
  }
  
  logAuthDebug('Auth restoration failed, user will need to log in');
  // Additional debugging for iOS PWA
  const debugInfo = {
    timestamp: new Date().toISOString(),
    action: "restoreAuth result",
    tokenState: localStorage.getItem("accessToken") ? "present" : "missing",
    userDataState: localStorage.getItem("userData") ? "present" : "missing"
  };

  // Add to PWA notification logs for visibility on debug page
  try {
    const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
    logs.push({
      timestamp: new Date().toISOString(),
      message: `[Auth Debug] ${JSON.stringify(debugInfo)}`
    });
    // Keep only last 50 logs
    while (logs.length > 50) logs.shift();
    localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
  } catch (e) {
    console.error("Error logging auth debug:", e);
  }

  return false;
};