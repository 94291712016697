import React from 'react';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaArrowRight, FaCalendarDay } from 'react-icons/fa';
import '../../stylez/DateToolBar.css';
import { format, addDays, subDays } from 'date-fns';

const BiWeeklyToolBar = ({ currentDate, setCurrentDate, startDate, endDate }) => {
    const handleTodayClick = () => {
        setCurrentDate(new Date());
    };

    const handleBackClick = () => {
        setCurrentDate(prevDate => subDays(prevDate, 14));
    };

    const handleNextClick = () => {
        setCurrentDate(prevDate => addDays(prevDate, 14));
    };

    const buttonVariants = {
        hover: { 
            scale: 1.05,
            transition: { 
                duration: 0.2,
                type: "tween"
            }
        },
        tap: { 
            scale: 0.95,
            transition: { 
                duration: 0.1
            }
        }
    };

    const labelVariants = {
        initial: { y: -5, opacity: 0 },
        animate: { 
            y: 0, 
            opacity: 1,
            transition: { 
                duration: 0.3,
                ease: "easeOut"
            }
        },
        exit: { 
            y: 5, 
            opacity: 0,
            transition: { 
                duration: 0.2
            }
        }
    };

    return (
        <motion.div 
            className="cjc-toolbar"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
        >
            <div className="cjc-btn-group">
                <motion.button 
                    type="button" 
                    onClick={handleBackClick}
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                >
                    <FaArrowLeft style={{ marginRight: '5px' }} /> Prev
                </motion.button>
                
                <motion.button 
                    type="button" 
                    onClick={handleTodayClick}
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                >
                    <FaCalendarDay style={{ marginRight: '5px' }} /> Current
                </motion.button>
                
                <motion.button 
                    type="button" 
                    onClick={handleNextClick}
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                >
                    Next <FaArrowRight style={{ marginLeft: '5px' }} />
                </motion.button>
            </div>
            
            <motion.span 
                className="cjc-toolbar-label"
                key={`${startDate}-${endDate}`}
                variants={labelVariants}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                {`${startDate} - ${endDate}`}
            </motion.span>
        </motion.div>
    );
}

export default BiWeeklyToolBar;