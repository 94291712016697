import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import '../../stylez/TotalHoursCounter.css';

const TotalHoursCounter = ({ events, type = 'single' }) => {
    const [totalEventHours, setTotalEventHours] = useState(0);

    useEffect(() => {
        if (type === 'all') {
            const totalEvents = Object.values(events).reduce((acc, coachEvents) => {
                return acc + coachEvents.length;
            }, 0);
            setTotalEventHours(totalEvents);
        } else {
            if (events) {
                setTotalEventHours(events.length);
            }
        }
    }, [events, type]);

    // Animation variants
    const counterVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        }
    };

    // Number counter animation
    const numberVariants = {
        hidden: { scale: 0.9, opacity: 0 },
        visible: { 
            scale: 1, 
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 200,
                damping: 10,
                delay: 0.1
            }
        }
    };

    return (
        <motion.div 
            className="total-hours-counter"
            variants={counterVariants}
            initial="hidden"
            animate="visible"
        >
            <h2>Total Hours</h2>
            
            <motion.span 
                className="hours-value"
                variants={numberVariants}
                initial="hidden"
                animate="visible"
                key={totalEventHours}
            >
                {totalEventHours}
            </motion.span>
        </motion.div>
    );
};

export default TotalHoursCounter;