import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Spinner, Alert, Modal } from 'react-bootstrap';
import { usePushNotifications } from '../../react-query/usePushNotifications';
import { useAuth } from '../../react-query/useAuth';
import { useNavigate } from 'react-router-dom';

const NotificationSettings = () => {
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState('');
  const [isChecking, setIsChecking] = useState(true);
  const [showUnsubscribeAllModal, setShowUnsubscribeAllModal] = useState(false);
  const { userData } = useAuth();
  const navigate = useNavigate();
  
  const { 
    subscribe, 
    unsubscribe,
    unsubscribeAll,
    isSubscribing,
    isUnsubscribing,
    isUnsubscribingAll,
    refetchSubscribedUsers,
    refetchMySubscription,
    subscribedUsers,
    isLoadingUsers,
    mySubscription,
    isLoadingMySubscription
  } = usePushNotifications();

  // Check if push notifications are supported
  const isPushSupported = 'PushManager' in window && 'serviceWorker' in navigator;

  // Check current notification status on component mount
  useEffect(() => {
    const checkNotificationStatus = async () => {
      setIsChecking(true);
      
      try {
        // Check permission status
        setPermissionStatus(Notification.permission);
        
        // Check for existing subscription
        if (isPushSupported) {
          const swRegistration = await navigator.serviceWorker.ready;
          const subscription = await swRegistration.pushManager.getSubscription();
          setCurrentSubscription(subscription);
        }
      } catch (error) {
        console.error('Error checking notification status:', error);
      } finally {
        setIsChecking(false);
      }
    };

    checkNotificationStatus();
  }, [isPushSupported]);

  // Subscribe to notifications
  const handleEnableNotifications = async () => {
    try {
      const result = await subscribe();
      if (result.success) {
        // Refresh subscription status
        const swRegistration = await navigator.serviceWorker.ready;
        const subscription = await swRegistration.pushManager.getSubscription();
        setCurrentSubscription(subscription);
        setPermissionStatus('granted');
        refetchSubscribedUsers();
      }
    } catch (error) {
      console.error('Error enabling notifications:', error);
    }
  };

  // Unsubscribe from notifications on this device
  const handleDisableNotifications = async () => {
    if (!currentSubscription) return;
    
    try {
      const result = await unsubscribe(currentSubscription);
      if (result.success) {
        setCurrentSubscription(null);
        refetchSubscribedUsers();
      }
    } catch (error) {
      console.error('Error disabling notifications:', error);
    }
  };

  // Unsubscribe all devices for the current user
  const handleUnsubscribeAllDevices = async () => {
    // if (!userData?.id) return;
    
    try {
      const result = await unsubscribeAll();
      if (result.success) {
        // Reset local state
        setCurrentSubscription(null);
        setPermissionStatus(Notification.permission);
        setShowUnsubscribeAllModal(false);
        
        // Refresh subscription information
        refetchMySubscription();
        refetchSubscribedUsers();
      }
    } catch (error) {
      console.error('Error unsubscribing all devices:', error);
    }
  };

  // Get user-friendly status message
  const getStatusMessage = () => {
    if (!isPushSupported) {
      return 'Your browser does not support push notifications.';
    }
    
    if (isChecking) {
      return 'Checking notification status...';
    }
    
    if (permissionStatus === 'denied') {
      return 'You have blocked notifications for this site. To enable them, you need to change your browser settings.';
    }
    
    if (currentSubscription) {
      return 'Notifications are enabled for this device.';
    }
    
    return 'Notifications are currently disabled.';
  };

  // Count how many devices the user has subscribed
  // First try using the user-specific endpoint (works for all users)
  // Fall back to the admin endpoint filtering if needed (for backward compatibility)
  const userDeviceCount = mySubscription?.subscription_count || 
    (subscribedUsers ? 
      subscribedUsers.find(sub => sub.email === userData?.email)?.subscription_count || 0 :
      0);

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5>Notification Settings</h5>
      </Card.Header>
      <Card.Body>
        <Alert variant="info">
          <p className="mb-0">
            <strong>How notifications work:</strong> When enabled, you'll receive alerts about new 
            programming, schedule changes, and important announcements directly to your device.
          </p>
          <hr />
          <p className="mb-0">
            <strong>Important:</strong> Your notification permissions stay active until you either:
          </p>
          <ul className="mt-2 mb-0">
            <li>Manually disable them in the settings below</li>
            <li>Clear your browser data/cache</li>
            <li>Uninstall the app from your device</li>
          </ul>
          <hr />
          <p className="mb-0">
            You can enable notifications on multiple devices (phone, tablet, computer) and manage each separately.
            Your notification settings are unique to each browser and device.
          </p>
          <hr />
          {userData?.is_admin && (
            <div className="d-flex justify-content-end">
              <Button 
                variant="outline-secondary"
                size="sm"
                className="mt-2"
                onClick={(e) => {
                  e.preventDefault();
                  // Use React Router navigation to prevent full page reload
                  try {
                    // Store a test message to ensure localStorage works
                    localStorage.setItem('debug_access_flag', 'clicked_from_settings');
                    
                    // Navigate using React Router instead of direct link
                    navigate('/notifications/debug');
                  } catch (e) {
                    console.error('Error navigating to debug page:', e);
                  }
                }}
              >
                🛠️ App Debug Console
              </Button>
            </div>
          )}
        </Alert>

        <div className="d-flex align-items-center mb-3">
          <div className="me-3">
            {isChecking ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <span className={`notification-status ${currentSubscription ? 'text-success' : 'text-muted'}`}>
                {getStatusMessage()}
              </span>
            )}
          </div>
        </div>

        <div className="d-grid gap-2">
          {/* Enable notifications button */}
          {!currentSubscription && permissionStatus !== 'denied' && (
            <Button 
              variant="primary" 
              onClick={handleEnableNotifications} 
              disabled={isSubscribing || !isPushSupported}
            >
              {isSubscribing ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Enabling Notifications...
                </>
              ) : (
                'Enable Notifications on This Device'
              )}
            </Button>
          )}

          {/* Disable notifications button */}
          {currentSubscription && (
            <Button 
              variant="outline-danger" 
              onClick={handleDisableNotifications} 
              disabled={isUnsubscribing}
            >
              {isUnsubscribing ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Disabling Notifications...
                </>
              ) : (
                'Disable Notifications on This Device'
              )}
            </Button>
          )}

          {/* Disable notifications on all devices button */}
          {userDeviceCount > 1 && (
            <Button 
              variant="danger" 
              onClick={() => setShowUnsubscribeAllModal(true)}
              disabled={isUnsubscribingAll || isLoadingUsers}
              className="mt-2"
            >
              {isUnsubscribingAll ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Disabling on All Devices...
                </>
              ) : (
                `Disable Notifications on All My Devices (${userDeviceCount})`
              )}
            </Button>
          )}

          {/* Permission denied helper */}
          {permissionStatus === 'denied' && (
            <Alert variant="warning">
              <p>You've blocked notifications in your browser settings. To enable them:</p>
              <ol className="mb-0">
                <li>Look for the site settings icon in your browser's address bar</li>
                <li>Change the notification permission from "Block" to "Allow"</li>
                <li>Refresh this page after changing the setting</li>
              </ol>
            </Alert>
          )}
        </div>

        {/* Unsubscribe from all devices confirmation modal */}
        <Modal show={showUnsubscribeAllModal} onHide={() => setShowUnsubscribeAllModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Disable All Notifications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to disable notifications on all your devices?</p>
            <p>This will remove notification permissions from every device where you've enabled them (phone, tablet, computer, etc).</p>
            <p>You can always re-enable notifications on any device later.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUnsubscribeAllModal(false)}>
              Cancel
            </Button>
            <Button 
              variant="danger" 
              onClick={handleUnsubscribeAllDevices}
              disabled={isUnsubscribingAll}
            >
              {isUnsubscribingAll ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Disabling...
                </>
              ) : (
                'Disable All Notifications'
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export default NotificationSettings;