import React, { useState, useEffect } from 'react';
import { usePushNotifications } from '../react-query/usePushNotifications';
import styles from '../stylez/NotificationDebugger.module.css';

// Helper function to format timestamps
const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
};

function NotificationDebugger() {
  const [logs, setLogs] = useState([]);
  const [swStatus, setSWStatus] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { diagnoseSWStatus } = usePushNotifications();
  
  // Load logs from localStorage when component mounts
  useEffect(() => {
    const storedLogs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
    setLogs(storedLogs);
    
    // Set up log watcher
    const logInterval = setInterval(() => {
      try {
        const updatedLogs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
        setLogs(updatedLogs);
      } catch (e) {
        console.error('Error loading logs:', e);
      }
    }, 2000); // Check every 2 seconds
    
    return () => clearInterval(logInterval);
  }, []);
  
  // Function to check service worker status
  const checkStatus = async () => {
    try {
      const status = await diagnoseSWStatus();
      setSWStatus(status);
    } catch (error) {
      setSWStatus({ error: error.toString() });
    }
  };
  
  // Function to clear logs
  const clearLogs = () => {
    localStorage.setItem('pwa_notification_logs', '[]');
    setLogs([]);
  };
  
  // Function to manually register service worker
  const forceRegisterSW = async () => {
    try {
      const reg = await navigator.serviceWorker.register('/service-worker.js');
      setSWStatus({ message: 'Registration attempted', registration: reg });
    } catch (error) {
      setSWStatus({ error: error.toString() });
    }
  };
  
  // Function to create a test notification
  const testNotification = () => {
    if (Notification.permission === 'granted' && navigator.serviceWorker.controller) {
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification('Debug Test', {
          body: 'This is a test notification to verify functionality',
          icon: '/pace_152x152_fav.png'
        });
      });
    } else {
      alert(`Cannot show notification: Permission=${Notification.permission}, Controller=${!!navigator.serviceWorker.controller}`);
    }
  };
  
  if (!isOpen) {
    return (
      <div 
        className={styles.debugButton} 
        onClick={() => setIsOpen(true)}
        title="Open Notification Debugger"
      >
        🐞
      </div>
    );
  }
  
  return (
    <div className={styles.debuggerPanel}>
      <div className={styles.debuggerHeader}>
        <h3>Notification Debugger</h3>
        <button onClick={() => setIsOpen(false)} className={styles.closeButton}>Close</button>
      </div>
      
      <div className={styles.statusSection}>
        <h4>Service Worker Status</h4>
        <div className={styles.statusInfo}>
          <p><strong>Controller:</strong> {navigator.serviceWorker.controller ? 'Active' : 'Not Active'}</p>
          <p><strong>Notification Permission:</strong> {Notification.permission}</p>
          <p><strong>PushManager Support:</strong> {'PushManager' in window ? 'Yes' : 'No'}</p>
        </div>
        
        <div className={styles.buttonGroup}>
          <button onClick={checkStatus} className={styles.actionButton}>Check Status</button>
          <button onClick={forceRegisterSW} className={styles.actionButton}>Force Register SW</button>
          <button onClick={testNotification} className={styles.actionButton}>Test Notification</button>
        </div>
        
        {Object.keys(swStatus).length > 0 && (
          <div className={styles.jsonContainer}>
            <pre>{JSON.stringify(swStatus, null, 2)}</pre>
          </div>
        )}
      </div>
      
      <div className={styles.logSection}>
        <div className={styles.logHeader}>
          <h4>Debug Logs ({logs.length})</h4>
          <button onClick={clearLogs} className={styles.clearButton}>Clear Logs</button>
        </div>
        
        <div className={styles.logContainer}>
          {logs.length === 0 ? (
            <p className={styles.emptyLog}>No logs recorded yet.</p>
          ) : (
            logs.map((log, index) => (
              <div key={index} className={styles.logEntry}>
                <span className={styles.logTime}>{formatTime(log.timestamp)}</span>
                <span className={styles.logMessage}>{log.message}</span>
              </div>
            ))
          )}
        </div>
      </div>
      
      <div className={styles.troubleshootingSection}>
        <h4>Troubleshooting Steps</h4>
        <ol>
          <li>Close the app completely (remove from app switcher)</li>
          <li>Reopen the app from the home screen icon</li>
          <li>Wait a few seconds for service worker activation</li>
          <li>Try enabling notifications again</li>
        </ol>
      </div>
    </div>
  );
}

export default NotificationDebugger;