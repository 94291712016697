import React, { useState } from 'react';
import { usePushNotifications } from '../react-query/usePushNotifications';
import { useAuth } from '../react-query/useAuth';
import UserSelector from './UserSelector';

function NotificationTester({ styles }) {
  const { userData } = useAuth();
  const { 
    sendNotification,
    broadcastToAll,
    isSendingNotification,
    isBroadcasting,
    subscribedUsers,
    isLoadingUsers
  } = usePushNotifications();
  
  const [notificationTitle, setNotificationTitle] = useState('Test Notification');
  const [notificationBody, setNotificationBody] = useState('This is a test notification from P.A.C.E.');
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [notificationType, setNotificationType] = useState('specific'); // 'specific' or 'broadcast'

  const handleSendNotification = async () => {
    try {
      if (notificationType === 'broadcast') {
        // Use broadcast for sending to all users
        await broadcastToAll(
          notificationTitle,
          notificationBody,
          { url: window.location.href }
        );
      } else {
        // Use targeted sending for specific users
        if (selectedUserIds.length === 0) {
          alert('Please select at least one user to send the notification to.');
          return;
        }
        
        await sendNotification(
          notificationTitle,
          notificationBody,
          { url: window.location.href },
          selectedUserIds
        );
      }
    } catch (error) {
      console.error('Failed to send notification:', error);
    }
  };

  // Direct method that uses the service worker to show a notification
  // This works even without the backend
  const showLocalNotification = () => {
    if (!('serviceWorker' in navigator)) {
      alert('Service workers are not supported in this browser');
      return;
    }

    // First check if we have permission
    if (Notification.permission !== 'granted') {
      alert('You need to allow notifications to test this feature');
      return;
    }

    // Check if service worker is ready and has an active controller
    if (!navigator.serviceWorker.controller) {
      alert('No active service worker found. Please reload the page');
      return;
    }

    // Send a message to the service worker to trigger a notification
    navigator.serviceWorker.ready.then(registration => {
      // For testing, we can directly show a notification via the service worker
      registration.showNotification(notificationTitle, {
        body: notificationBody,
        icon: '/pace_152x152_fav.png',
        badge: '/pace_32x32_fav.png',
        data: {
          url: window.location.href
        },
        actions: [
          {
            action: 'open',
            title: 'Open App'
          },
          {
            action: 'close',
            title: 'Close'
          }
        ]
      });
    });
  };

  const handleUserSelectionChange = (userIds) => {
    setSelectedUserIds(userIds);
  };

  return (
    <div className={styles?.notificationTester || "notification-tester"}>
      <h3>Test Push Notifications</h3>
      <p>Use the form below to send a test notification. Make sure you've enabled notifications first.</p>
      
      <div className={styles?.typeSelector || "type-selector"}>
        <label>Notification Type:</label>
        <div className={styles?.radioGroup || "radio-group"}>
          <label>
            <input
              type="radio"
              name="notificationType"
              value="specific"
              checked={notificationType === 'specific'}
              onChange={() => setNotificationType('specific')}
            />
            Send to specific users
          </label>
          <label>
            <input
              type="radio"
              name="notificationType"
              value="broadcast"
              checked={notificationType === 'broadcast'}
              onChange={() => setNotificationType('broadcast')}
            />
            Broadcast to all users
          </label>
        </div>
      </div>
      
      <div className={styles?.formGroup || "form-group"}>
        <label htmlFor="notificationTitle">Notification Title</label>
        <input
          type="text"
          id="notificationTitle"
          value={notificationTitle}
          onChange={(e) => setNotificationTitle(e.target.value)}
          className={styles?.input || ""}
        />
      </div>
      
      <div className={styles?.formGroup || "form-group"}>
        <label htmlFor="notificationBody">Notification Message</label>
        <textarea
          id="notificationBody"
          value={notificationBody}
          onChange={(e) => setNotificationBody(e.target.value)}
          className={styles?.textarea || ""}
          rows="3"
        />
      </div>
      
      {notificationType === 'specific' && (
        <UserSelector 
          users={subscribedUsers}
          onSelectionChange={handleUserSelectionChange}
          disabled={isSendingNotification || isLoadingUsers}
        />
      )}
      
      <div className={styles?.buttonGroup || "button-group"}>
        <button 
          onClick={handleSendNotification}
          className={styles?.testButton || ""}
          disabled={
            isSendingNotification || 
            isBroadcasting || 
            (notificationType === 'specific' && selectedUserIds.length === 0)
          }
        >
          {isSendingNotification || isBroadcasting 
            ? 'Sending...' 
            : notificationType === 'broadcast' 
              ? 'Send to All Users'
              : 'Send to Selected Users'
          }
        </button>
        
        <button 
          onClick={showLocalNotification}
          className={styles?.localTestButton || ""}
        >
          Show Local Notification
        </button>
      </div>
      
      <p className={styles?.note || "note"}>
        <small>
          The "Send to Selected Users" button sends notifications to specific users.
          The "Send to All Users" button sends notifications to all subscribed users.
          The "Show Local Notification" button shows a notification immediately without the server (useful for quick testing).
        </small>
      </p>
    </div>
  );
}

export default NotificationTester;