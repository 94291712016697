import { useState } from 'react';
import { motion } from 'framer-motion';
import useProgUpload, { useDailyVideoUpdate } from '../react-query/useProgUpload';
import styles from '../stylez/ProgrammingUploadPage.module.css';

const ProgUpload = () => {
    // State for PDF upload
    const [selectedFile, setSelectedFile] = useState(null);
    const { mutate: uploadFile, isLoading: isFileUploading } = useProgUpload();
    
    // State for YouTube video update
    const [selectedDay, setSelectedDay] = useState('Monday');
    const [videoLink, setVideoLink] = useState('');
    const { mutate: updateVideo, isLoading: isVideoUpdating } = useDailyVideoUpdate();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
          uploadFile(selectedFile);
        }
    };

    const handleVideoLinkChange = (e) => {
        setVideoLink(e.target.value);
    };

    const handleDayChange = (e) => {
        setSelectedDay(e.target.value);
    };

    const handleVideoUpdate = () => {
        if (videoLink && selectedDay) {
            updateVideo({
                day: selectedDay,
                video_link: videoLink
            });
            // Clear the form after submission
            setVideoLink('');
        }
    };

    const weekdays = [
        'Monday', 'Tuesday', 'Wednesday', 'Thursday', 
        'Friday', 'Saturday', 'Sunday'
    ];

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.4
            }
        }
    };

    const buttonVariants = {
        hover: {
            scale: 1.02,
            transition: { duration: 0.2 }
        },
        tap: {
            scale: 0.98,
            transition: { duration: 0.1 }
        }
    };

    return (
    <motion.div 
        className={styles.programmingUploadPageContainer}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
    >
      <motion.h1 
        className={styles.title}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        Programming Upload
      </motion.h1>
      
      {/* Weekly PDF Upload Section */}
      <motion.div 
        className={styles.uploadCard}
        variants={cardVariants}
      >
        <div className={styles.uploadSection}>
          <motion.h2 
            className={styles.sectionTitle}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.3 }}
          >
            Upload Weekly PDF
          </motion.h2>
          <div className={styles.inputContainer}>
              {selectedFile && <p className={styles.fileName}>{selectedFile.name}</p>}
                <input 
                    type="file" 
                    id="fileUpload" 
                    className={styles.fileInput} 
                    onChange={handleFileChange} 
                />
                <motion.label 
                    htmlFor="fileUpload" 
                    className={styles.fileLabel}
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                >
                    Choose File
                </motion.label>
                <motion.button 
                    onClick={handleUpload} 
                    className={styles.uploadButton}
                    disabled={isFileUploading || !selectedFile}
                    variants={buttonVariants}
                    whileHover={!isFileUploading && selectedFile ? "hover" : undefined}
                    whileTap={!isFileUploading && selectedFile ? "tap" : undefined}
                >
                    {isFileUploading ? 'Uploading...' : 'Upload PDF'}
                </motion.button>
            </div>
        </div>
        
        {/* Daily Video Update Section */}
        <div className={styles.uploadSection}>
          <motion.h2 
            className={styles.sectionTitle}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.3 }}
          >
            Update Daily Video
          </motion.h2>
          <motion.div 
            className={styles.videoUpdateForm}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.3 }}
          >
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>Day of the Week</label>
              <motion.select 
                className={styles.formSelect}
                value={selectedDay}
                onChange={handleDayChange}
                whileFocus={{ boxShadow: "0 0 0 2px rgba(52, 152, 219, 0.4)" }}
              >
                {weekdays.map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </motion.select>
            </div>
            
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>YouTube Video Link</label>
              <motion.input
                type="text"
                className={styles.formInput}
                value={videoLink}
                onChange={handleVideoLinkChange}
                placeholder="https://www.youtube.com/watch?v=..."
                whileFocus={{ boxShadow: "0 0 0 2px rgba(52, 152, 219, 0.4)" }}
              />
            </div>
            
            <motion.button 
              onClick={handleVideoUpdate}
              className={styles.uploadButton}
              disabled={isVideoUpdating || !videoLink}
              style={{ marginTop: '10px' }}
              variants={buttonVariants}
              whileHover={!isVideoUpdating && videoLink ? "hover" : undefined}
              whileTap={!isVideoUpdating && videoLink ? "tap" : undefined}
            >
              {isVideoUpdating ? 'Updating...' : 'Update Video'}
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};
export default ProgUpload;