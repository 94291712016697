import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import DayWorkedCard from './DayWorkedCard';
import { FaCalendarTimes } from 'react-icons/fa';
import '../../stylez/HoursWorkedList.css';

function HoursWorkedCardsList({ events }) {
  // Create date without timezone issues
  const createDateWithoutTimeZone = (dateString) => {
    const [year, month, day] = dateString.split('T')[0].split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  // Group events by date
  const eventsByDate = {};
  
  if (events && events.length > 0) {
    events.forEach(event => {
      const date = new Date(event.start);
      const eventDate = date.toLocaleDateString('en-US', { 
        timeZone: 'UTC', 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      });
      
      if (!eventsByDate[eventDate]) {
        eventsByDate[eventDate] = [];
      }
      eventsByDate[eventDate].push(event);
    });
  }

  // Sort the dates
  const sortedDates = Object.keys(eventsByDate).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  });

  // Container animation
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.07
      }
    }
  };

  // No hours message variant
  const messageVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        delay: 0.1,
        duration: 0.3
      }
    }
  };

  // Icon animation
  const iconVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    }
  };

  return (
    <motion.div 
      className="hours-worked-cards-list"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <AnimatePresence>
        {sortedDates.length > 0 ? (
          sortedDates.map(date => (
            <DayWorkedCard 
              key={date} 
              date={date} 
              events={eventsByDate[date]} 
            />
          ))
        ) : (
          <motion.div 
            className="no-hours-message"
            variants={messageVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div
              variants={iconVariants}
            >
              <FaCalendarTimes className="no-hours-icon" />
            </motion.div>
            <h3>No Hours Recorded</h3>
            <p>There are no hours recorded for this period.</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default HoursWorkedCardsList;