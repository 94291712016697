// src/services/api_push.js
import { dataInstance } from "./axiosInstance";

// Helper function to log events to localStorage for debugging
const logToStorage = (msg, data = null) => {
  try {
    const message = data ? `${msg}: ${JSON.stringify(data)}` : msg;
    console.log(`[Push API] ${message}`);

    const logs = JSON.parse(
      localStorage.getItem("pwa_notification_logs") || "[]"
    );
    logs.push({
      timestamp: new Date().toISOString(),
      message: `[Push API] ${message}`,
    });
    // Keep only last 50 logs
    while (logs.length > 50) logs.shift();
    localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
  } catch (e) {
    console.error("Error logging:", e);
  }
};

// Save a new push subscription to the server
export const saveSubscription = async (subscription) => {
  try {
    logToStorage("Saving subscription to server", {
      endpoint: subscription.endpoint,
    });
    const response = await dataInstance.post("/push/subscribe", subscription);
    logToStorage("Subscription saved successfully", response.data);
    return response.data;
  } catch (error) {
    logToStorage("Error saving push subscription", { error: error.toString() });
    console.error("Error saving push subscription:", error);
    throw error;
  }
};

// Remove a push subscription from the server
export const removeSubscription = async (endpoint) => {
  try {
    logToStorage("Removing subscription from server", { endpoint });
    const response = await dataInstance.delete("/push/unsubscribe", {
      data: { endpoint },
    });
    logToStorage("Subscription removed successfully");
    return response.data;
  } catch (error) {
    logToStorage("Error removing subscription", { error: error.toString() });
    console.error("Error removing push subscription:", error);
    throw error;
  }
};

// Send a notification to specific users
export const sendPushNotification = async (notification, userIds = null) => {
  try {
    // Check if running on iOS
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // For send endpoint, we need 'notification' object AND user_ids
    const payload = {
      notification: {
        title: notification.title,
        body: notification.body,
        data: notification.data || {},
        icon: "/pace_152x152_fav.png",
        badge: "/pace_32x32_fav.png",
      },
    };

    // Add iOS-specific properties for better delivery
    if (isIOS) {
      // Add iOS specific properties to help with delivery
      payload.notification.urgency = "high";
      payload.notification.priority = "high";
      payload.notification.time_to_live = 2419200; // 28 days in seconds

      // Add APNs specific properties inside the data field - as a string to satisfy backend validation
      payload.notification.data.apns = JSON.stringify({
        aps: {
          alert: {
            title: notification.title,
            body: notification.body,
          },
          badge: 1,
          "content-available": 1,
        },
      });

      // Make sure data is properly structured for iOS
      if (!payload.notification.data.url) {
        payload.notification.data.url = window.location.origin;
      }

      // Add a timestamp to avoid caching issues
      payload.notification._timestamp = Date.now();

      logToStorage("iOS detected, adding special notification properties");
    }

    // If user IDs are provided, include them in the request body
    if (userIds && Array.isArray(userIds) && userIds.length > 0) {
      payload.user_ids = userIds;
    }

    logToStorage("Sending notification with payload", payload);
    const response = await dataInstance.post("/push/send", payload);
    logToStorage("Server response for send notification", response.data);

    return response.data;
  } catch (error) {
    logToStorage("Error sending push notification", {
      error: error.toString(),
      response: error.response?.data,
    });
    console.error("Error sending push notification:", error);
    throw error;
  }
};

// Broadcast a notification to all users
export const broadcastNotification = async (notification) => {
  try {
    // Build a universal payload for broadcast notifications
    const payload = {
      title: notification.title,
      body: notification.body,
      data: notification.data || {},
      icon: "/pace_152x152_fav.png",
      badge: "/pace_32x32_fav.png",
      urgency: "high",
      priority: "high",
      time_to_live: 2419200, // 28 days in seconds
      _timestamp: Date.now(),
    };

    // Make sure we have a URL in the data
    if (!payload.data.url) {
      payload.data.url = window.location.origin;
    }

    logToStorage("Broadcasting notification with universal payload", payload);
    const response = await dataInstance.post("/push/broadcast", payload);
    logToStorage("Server response for broadcast", response.data);

    return response.data;
  } catch (error) {
    logToStorage("Error broadcasting notification", {
      error: error.toString(),
      response: error.response?.data,
    });
    console.error("Error broadcasting notification:", error);
    throw error;
  }
};

// Get list of users who have subscribed to push notifications (admin only)
export const getSubscribedUsers = async () => {
  try {
    logToStorage("Fetching subscribed users");
    const response = await dataInstance.get("/push/subscribed-users");
    logToStorage("Fetched subscribed users count", {
      count: response.data.length,
    });
    return response.data;
  } catch (error) {
    logToStorage("Error getting subscribed users", { error: error.toString() });
    console.error("Error getting subscribed users:", error);
    throw error;
  }
};

// Remove all subscriptions for a user across all devices
export const removeAllSubscriptions = async () => {
  try {
    logToStorage("Removing all subscriptions for current user");
    const response = await dataInstance.delete("/push/unsubscribe-all");
    logToStorage("All subscriptions removed successfully", response.data);
    return response.data;
  } catch (error) {
    logToStorage("Error removing all subscriptions", {
      error: error.toString(),
    });
    console.error("Error removing all push subscriptions:", error);
    throw error;
  }
};

// Get current user's subscription information (user-friendly endpoint)
export const getMySubscription = async () => {
  try {
    logToStorage("Fetching current user subscription info");
    const response = await dataInstance.get("/push/my-subscription");
    logToStorage("Fetched user subscription info", {
      count: response.data.subscription_count,
      hasSubscriptions: response.data.subscription_count > 0,
    });
    return response.data;
  } catch (error) {
    logToStorage("Error getting user subscription info", {
      error: error.toString(),
    });
    console.error("Error getting user subscription info:", error);
    // Return a default object with zero subscriptions instead of throwing
    return {
      user_id: "",
      user_name: "",
      email: "",
      subscription_count: 0,
      last_subscription: null,
    };
  }
};

// Admin function to toggle push notifications system-wide
export const togglePushNotifications = async (enable) => {
  try {
    logToStorage(
      `${enable ? "Enabling" : "Disabling"} push notifications system-wide`
    );
    // Send the boolean value directly, not in an object
    const boolValue = Boolean(enable);
    console.log("Sending raw boolean value:", boolValue);

    const response = await dataInstance.post(
      "/admin/toggle-push-notifications",
      boolValue
    );
    logToStorage(
      "System-wide push notification toggle response",
      response.data
    );
    return response.data;
  } catch (error) {
    logToStorage("Error toggling system-wide push notifications", {
      error: error.toString(),
      responseData: error.response?.data,
    });
    console.error("Error toggling system-wide push notifications:", error);
    console.error("Response data:", error.response?.data);
    throw error;
  }
};

// Admin function to get push notifications status
export const getPushNotificationsStatus = async () => {
  try {
    logToStorage("Fetching system-wide push notifications status");
    const response = await dataInstance.get("/admin/push-notifications-status");
    logToStorage("Push notifications status", response.data);
    return response.data;
  } catch (error) {
    logToStorage("Error getting push notifications status", {
      error: error.toString(),
      responseData: error.response?.data,
    });
    console.error("Error getting push notifications status:", error);
    console.error("Response data:", error.response?.data);
    throw error;
  }
};
