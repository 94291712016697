import React from "react";
import { Card, Form, Button, Spinner, Alert } from "react-bootstrap";
import { usePushNotifications } from "../../react-query/usePushNotifications";

const AdminNotificationControl = () => {
  const {
    pushNotificationsStatus,
    isLoadingPushNotificationsStatus,
    toggleSystemPushNotifications,
    isTogglingSystemPushNotifications,
    refetchPushNotificationsStatus,
  } = usePushNotifications();

  // Handle toggle
  const handleToggle = () => {
    // Toggle to opposite of current state
    const newStatus = !pushNotificationsStatus?.push_notifications_enabled;
    toggleSystemPushNotifications(Boolean(newStatus));
  };

  // Default to true if status is undefined
  const isEnabled =
    pushNotificationsStatus?.push_notifications_enabled !== false;

  return (
    <Card className="mb-3">
      <Card.Header className="bg-warning text-dark">
        <h5>System-Wide Notification Controls</h5>
      </Card.Header>
      <Card.Body>
        <Alert variant="info">
          <p className="mb-0">
            <strong>Admin Control Panel:</strong> You can enable or disable push
            notifications for all users system-wide.
          </p>
          <hr />
          <p className="mb-0">When notifications are disabled:</p>
          <ul className="mt-2 mb-0">
            <li>
              All notification endpoints still work but return a "skipped"
              status
            </li>
            <li>No users will receive push notifications</li>
            <li>
              Users can still subscribe/unsubscribe, but won't receive
              notifications until you enable the system
            </li>
          </ul>
        </Alert>

        <div className="d-flex align-items-center mb-3">
          <div className="me-3">
            {isLoadingPushNotificationsStatus ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <span className={isEnabled ? "text-success" : "text-danger"}>
                <strong>Current status:</strong> Push notifications are{" "}
                {isEnabled ? "ENABLED" : "DISABLED"} system-wide
              </span>
            )}
          </div>
        </div>

        <div className="d-flex align-items-center">
          <Form.Check
            type="switch"
            id="system-notification-switch"
            label={isEnabled ? "Enabled" : "Disabled"}
            checked={isEnabled}
            onChange={handleToggle}
            disabled={
              isTogglingSystemPushNotifications ||
              isLoadingPushNotificationsStatus
            }
            className="me-3"
          />
          <Button
            variant={isEnabled ? "danger" : "success"}
            onClick={handleToggle}
            disabled={
              isTogglingSystemPushNotifications ||
              isLoadingPushNotificationsStatus
            }
          >
            {isTogglingSystemPushNotifications ? (
              <>
                <Spinner animation="border" size="sm" className="me-2" />
                Updating...
              </>
            ) : isEnabled ? (
              "Disable All Notifications"
            ) : (
              "Enable All Notifications"
            )}
          </Button>

          <Button
            variant="outline-secondary"
            size="sm"
            className="ms-2"
            onClick={() => refetchPushNotificationsStatus()}
            disabled={isLoadingPushNotificationsStatus}
          >
            {isLoadingPushNotificationsStatus ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "↻"
            )}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AdminNotificationControl;
