import React, { useState } from 'react';
import '../../stylez/EventList.css';
import { FaDumbbell } from 'react-icons/fa';
import { format } from 'date-fns';
import { useSwipeable } from "react-swipeable";
import { useAuth } from "../../react-query/useAuth";
import { motion } from 'framer-motion';

function EventCard({ event, view = "Maker", onRemove = () => {}, onClaim = () => {}, onDeleteClick = null }) {
    const [animationClass, setAnimationClass] = useState('');
    const { userData } = useAuth();
    const startDate = new Date(event.start);

    const formattedTime = format(startDate, 'hh:mm a');
    const classType = event.type ? event.type : 'Class Type';

    // Handle swipe gesture
    const handleSwipe = (eventData) => {
        if (!view || view === 'Delete') {
            return;  // Only allow swiping in the 'Maker' view
        }
        
        if (event.title === 'OPEN' || event.title === '') return; // Don't allow swiping on open slots
        if (event.title !== userData.first_name && !userData.is_admin) return; // Only allow swiping if the event belongs to the current user or if the user is an admin

        if (eventData.dir === 'Left' || eventData.dir === 'Right') {
            setAnimationClass('animate__animated animate__slideOut' + eventData.dir);
            setTimeout(() => setAnimationClass('animate__animated animate__fadeIn'), 1500); // Reset animation after 1.5 seconds
        }
    };

    // Configure swipe handlers using react-swipeable
    const swipeHandlers = useSwipeable({
        onSwiped: handleSwipe,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    // Handle animation end
    const handleAnimationEnd = (cur_el) => {
        if (animationClass.includes('slideOut')) {
            if (onRemove) {
                onRemove();
                setAnimationClass('animate__animated animate__fadeIn');
            }
        }
    };

    // Handle event claiming
    const handleClaim = () => {
        if (!view) return; // Only allow claiming in the 'Viewer' view
        if (event.title === userData.first_name) {
            console.log('You are already signed up for this event');
        } else {
            onClaim();
        }
    };

    // Determine what to display based on the view
    let titleDisplay, timeDisplay;
    if (view === "Delete") {
        titleDisplay = <h3 className="capitalized">{classType}</h3>; // Show class type instead of name
        timeDisplay = <p className="event-time">{formattedTime}</p>; // Show only the time
    } else {
        titleDisplay = <h3>{event.title ? event.title : 'OPEN'}</h3>;
        timeDisplay = (
            <>
                <p className="event-time">{formattedTime}</p>
                <p className="event-class-type">{classType}</p>
            </>
        );
    }

    // Framer motion variants
    const cardVariants = {
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        exit: { opacity: 0, x: -100, transition: { duration: 0.3 } },
        hover: { 
            y: -3, 
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.12)',
            transition: { duration: 0.2 }
        },
        tap: { 
            y: 0, 
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', 
            transition: { duration: 0.1 } 
        }
    };

    // Get border color based on class type
    const getBorderColor = () => {
        const colors = {
            crossfit: '#e74c3c',
            onRamp: '#3498db',
            barbell: '#9b59b6',
            openGym: '#2ecc71',
            hero: '#f1c40f',
            other: '#e67e22'
        };
        
        return colors[event.type] || 'transparent';
    };

    return (
        <motion.div
            className={`event-card ${animationClass}`}
            {...swipeHandlers}
            onAnimationEnd={() => handleAnimationEnd(event)}
            onClick={view === "Delete" ? onDeleteClick : handleClaim}
            initial="initial"
            animate="animate"
            exit="exit"
            whileHover="hover"
            whileTap="tap"
            variants={cardVariants}
            style={{ borderLeftColor: getBorderColor() }}
        >
            <div className="card-icon">
                <FaDumbbell />
            </div>
            <div className="divider"></div>
            <div className="event-title">{titleDisplay}</div>
            <div className="event-time-only">{timeDisplay}</div>
        </motion.div>
    );
}

export default EventCard;