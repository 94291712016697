import React, { useState, useRef, useEffect } from 'react';
import '../stylez/AddClassPage.css';
import { ButtonGroup } from 'react-bootstrap';
import useScheduleMaker from '../react-query/useScheduleMaker';
import EventList from '../components/ScheduleComponents/EventList';
import { useEvents } from '../react-query/useEvents';
import { FaCalendarDay, FaCalendarWeek, FaTrash, FaPlus, FaCalendarAlt } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';

const classTypes = {
  crossfit: { name: 'CrossFit', color: 'bg-custom-red' },
  onRamp: { name: 'On Ramp', color: 'bg-custom-blue' },
  barbell: { name: 'Barbell', color: 'bg-custom-purple' },
  openGym: { name: 'Open Gym', color: 'bg-custom-green' },
  hero: { name: 'Hero', color: 'bg-custom-yellow' },
  other: { name: 'Other', color: 'bg-custom-orange' }
};

const AddClassPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const {handleCreateSchedule, handleDeleteEvent} = useScheduleMaker();
  const { events, isLoading, isError } = useEvents({ startDate: selectedDate, fetchType: 'singleDay' });
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedDays, setSelectedDays] = useState(new Array(7).fill(false));
  const inputContainerRef = useRef(null);
  const reoccurringInputContainerRef = useRef(null);
  
  const [singleClassDetails, setSingleClassDetails] = useState({
    classType: '',
    date: selectedDate,
    startTime: '',
    endTime: '',
  });
  
  const [recurringClassDetails, setRecurringClassDetails] = useState({
    classType: '',
    startDate: selectedDate,
    endDate: '',
    startTime: '',
    endTime: '',
  });
  
  const todaysEvents = events?.events ?? [];
  const available_events = events?.availableEvents ?? [];
  const combinedEvents = [...todaysEvents, ...available_events];

  // Handle date change
  const handleDateChange = async (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
  };
  
  // Handle input changes
  const handleSingleClassInputChange = (e) => {
    setSingleClassDetails({
      ...singleClassDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleRecurringClassInputChange = (e) => {
    setRecurringClassDetails({
      ...recurringClassDetails,
      [e.target.name]: e.target.value,
    });
  };

  // Submit functions
  const submitSingleClass = async () => {
    try {
      const { classType, date, startTime, endTime } = singleClassDetails;
      const nxt = 0; // Placeholder, adjust as necessary
      await handleCreateSchedule(classType, false, startTime, endTime, date, nxt, []);
      
      // Reset form after successful submission
      setSingleClassDetails({
        ...singleClassDetails,
        classType: '',
        startTime: '',
        endTime: ''
      });
      
      // Show success feedback (you could add toast here)
      console.log('Single Class Submitted');
    } catch (error) {
      console.error('Error submitting single class:', error);
      // Show error feedback (you could add toast here)
    }
  };
  
  const submitRecurringClass = async () => {
    try {
      const { classType, startDate, startTime, endTime } = recurringClassDetails;
      
      // Convert selectedDays to day names
      const selectedDayNames = selectedDays.reduce((acc, isSelected, index) => {
        if (isSelected) acc.push(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][index]);
        return acc;
      }, []);
  
      await handleCreateSchedule(
        classType,
        true,
        startTime,
        endTime,
        startDate,
        0,
        selectedDayNames
      );
      
      // Reset form after submission
      setRecurringClassDetails({
        ...recurringClassDetails,
        classType: '',
        startTime: '',
        endTime: ''
      });
      setSelectedDays(new Array(7).fill(false));
      
      console.log('Recurring Class Submitted');
    } catch (error) {
      console.error('Error submitting recurring class:', error);
    }
  };
  
  // Delete an event
  const deleteEvent = async (event, all) => {
    try {
      await handleDeleteEvent(event, all);
      console.log('Event deleted:', event);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  // Handle card selection
  const handleCardClick = (buttonName) => {
    setSelectedButton(buttonName === selectedButton ? null : buttonName);
  };

  // Toggle day selection for recurring classes
  const toggleDay = (index) => {
    const newSelectedDays = [...selectedDays];
    newSelectedDays[index] = !newSelectedDays[index];
    setSelectedDays(newSelectedDays);
  };

  // Day of week buttons
  const renderDayButtons = () => {
    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return days.map((day, index) => (
      <button
        key={index}
        className={`day-button ${selectedDays[index] ? 'selected' : ''} ${selectedButton !== 'reoccurring' ? 'disabled' : ''}`}
        disabled={selectedButton !== 'reoccurring'}
        onClick={() => toggleDay(index)}
      >
        {day}
      </button>
    ));
  };
  
  // Animation variants for framer-motion
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.4,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
        ease: "easeIn"
      }
    }
  };
  
  const cardHeaderVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.02 }
  };
  
  return (
    <motion.div 
      className="add-class-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="header-container">
        <h1 className="page-title">Class Manager</h1>
        <p className="subtitle">Create, schedule, and manage your classes</p>
      </div>
      
      <div className="card-container">
        {/* Single Class Card */}
        <motion.div 
          className={`option-card ${selectedButton === 'single' ? 'selected' : ''}`}
          initial="hidden"
          animate="visible"
          variants={cardVariants}
          layout
        >
          <motion.div 
            className="card-header"
            onClick={() => handleCardClick('single')}
            whileHover="hover"
            variants={cardHeaderVariants}
          >
            <div className="card-header-content">
              <div className="card-icon">
                <FaCalendarDay />
              </div>
              <h3 className="card-title">Single Class</h3>
            </div>
            <FiChevronDown className="toggle-icon" />
          </motion.div>
          
          <motion.div 
            className="card-body"
            animate={{ 
              height: selectedButton === 'single' ? 'auto' : 0,
              opacity: selectedButton === 'single' ? 1 : 0
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="card-content" ref={inputContainerRef}>
              <div className="form-group">
                <label className="form-label">Class Type</label>
                <select 
                  name="classType" 
                  className="form-control"
                  onChange={handleSingleClassInputChange} 
                  value={singleClassDetails.classType}
                >
                  <option value="" disabled>Select Class Type</option>
                  {Object.entries(classTypes).map(([key, { name }]) => (
                    <option key={key} value={key}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label className="form-label">Date</label>
                <input 
                  type="date" 
                  name="date" 
                  className="form-control"
                  onChange={handleSingleClassInputChange} 
                  value={singleClassDetails.date}
                />
              </div>
              
              <div className="form-group">
                <label className="form-label">Start Time</label>
                <input 
                  type="time" 
                  name="startTime" 
                  className="form-control"
                  onChange={handleSingleClassInputChange} 
                  value={singleClassDetails.startTime}
                />
              </div>
              
              <div className="form-group">
                <label className="form-label">End Time</label>
                <input 
                  type="time" 
                  name="endTime" 
                  className="form-control"
                  onChange={handleSingleClassInputChange}
                  value={singleClassDetails.endTime}
                />
              </div>
              
              <motion.button 
                className="submit-button"
                onClick={submitSingleClass}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
              >
                <FaPlus />
                <span>Add Class</span>
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
        
        {/* Recurring Class Card */}
        <motion.div 
          className={`option-card ${selectedButton === 'reoccurring' ? 'selected' : ''}`}
          initial="hidden"
          animate="visible"
          variants={cardVariants}
          layout
          transition={{ delay: 0.1 }}
        >
          <motion.div 
            className="card-header"
            onClick={() => handleCardClick('reoccurring')}
            whileHover="hover"
            variants={cardHeaderVariants}
          >
            <div className="card-header-content">
              <div className="card-icon">
                <FaCalendarWeek />
              </div>
              <h3 className="card-title">Recurring Class</h3>
            </div>
            <FiChevronDown className="toggle-icon" />
          </motion.div>
          
          <motion.div 
            className="card-body"
            animate={{ 
              height: selectedButton === 'reoccurring' ? 'auto' : 0,
              opacity: selectedButton === 'reoccurring' ? 1 : 0
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="card-content" ref={reoccurringInputContainerRef}>
              <div className="form-group">
                <label className="form-label">Class Type</label>
                <select 
                  name="classType" 
                  className="form-control"
                  onChange={handleRecurringClassInputChange}
                  value={recurringClassDetails.classType}
                >
                  <option value="" disabled>Select Class Type</option>
                  {Object.entries(classTypes).map(([key, { name }]) => (
                    <option key={key} value={key}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label className="form-label">Start Date</label>
                <input 
                  type="date" 
                  name="startDate" 
                  className="form-control"
                  onChange={handleRecurringClassInputChange}
                  value={recurringClassDetails.startDate}
                />
              </div>
              
              <div className="form-group">
                <label className="form-label">Start Time</label>
                <input 
                  type="time" 
                  name="startTime" 
                  className="form-control"
                  onChange={handleRecurringClassInputChange}
                  value={recurringClassDetails.startTime}
                />
              </div>
              
              <div className="form-group">
                <label className="form-label">End Time</label>
                <input 
                  type="time" 
                  name="endTime" 
                  className="form-control"
                  onChange={handleRecurringClassInputChange}
                  value={recurringClassDetails.endTime}
                />
              </div>
              
              <div className="form-group">
                <label className="form-label">Select Days</label>
                <div className="weekday-picker">
                  {renderDayButtons()}
                </div>
              </div>
              
              <motion.button 
                className="submit-button"
                onClick={submitRecurringClass}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
              >
                <FaPlus />
                <span>Add Recurring Class</span>
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
        
        {/* Delete Class Card */}
        <motion.div 
          className={`option-card delete-card ${selectedButton === 'delete' ? 'selected' : ''}`}
          initial="hidden"
          animate="visible"
          variants={cardVariants}
          layout
          transition={{ delay: 0.2 }}
        >
          <motion.div 
            className="card-header"
            onClick={() => handleCardClick('delete')}
            whileHover="hover"
            variants={cardHeaderVariants}
          >
            <div className="card-header-content">
              <div className="card-icon">
                <FaTrash />
              </div>
              <h3 className="card-title">Delete Class</h3>
            </div>
            <FiChevronDown className="toggle-icon" />
          </motion.div>
          
          <motion.div 
            className="card-body"
            animate={{ 
              height: selectedButton === 'delete' ? 'auto' : 0,
              opacity: selectedButton === 'delete' ? 1 : 0
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="card-content">
              <div className="form-group">
                <label className="form-label">Select Date</label>
                <div className="date-selector">
                  <FaCalendarAlt className="date-icon" />
                  <input 
                    type="date" 
                    value={selectedDate} 
                    onChange={handleDateChange}
                    className="form-control"
                  />
                </div>
              </div>
              
              <AnimatePresence>
                {isLoading ? (
                  <div className="loading-container">
                    <p>Loading events...</p>
                  </div>
                ) : (
                  <motion.div 
                    className="events-container"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <EventList 
                      events={combinedEvents} 
                      view="Delete"
                      dropClaimedEvent={deleteEvent}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default AddClassPage;