// indexedDBService.js - Handles persistence for authentication data
import { logAuthDebug } from '../functions/AuthDebug';

/**
 * Opens (or creates) the IndexedDB database for auth storage
 * @returns {Promise<IDBDatabase>} The database instance
 */
export const openDatabase = () => {
  logAuthDebug('IndexedDB: Attempting to open authDB database');
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('authDB', 1);
    
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      logAuthDebug('IndexedDB: Database upgrade needed', { version: event.oldVersion });
      if (!db.objectStoreNames.contains('auth')) {
        logAuthDebug('IndexedDB: Creating auth object store');
        // Create the auth store with a keypath of 'id'
        db.createObjectStore('auth', { keyPath: 'id' });
      }
    };
    
    request.onsuccess = (event) => {
      logAuthDebug('IndexedDB: Database opened successfully');
      resolve(event.target.result);
    };
    
    request.onerror = (event) => {
      const error = event.target.error;
      logAuthDebug('IndexedDB: Error opening database', { error: error.message });
      console.error('IndexedDB error:', error);
      reject(error);
    };
  });
};

/**
 * Stores authentication data in IndexedDB
 * @param {string} token - The access token
 * @param {Object} userData - The user data object
 * @returns {Promise<void>}
 */
export const storeAuthDataInIndexedDB = async (token, userData) => {
  logAuthDebug('IndexedDB: Attempting to store auth data', { 
    hasToken: !!token,
    hasUserData: !!userData
  });
  
  try {
    const db = await openDatabase();
    logAuthDebug('IndexedDB: Database opened for storing auth data');
    
    return new Promise((resolve, reject) => {
      const tx = db.transaction('auth', 'readwrite');
      const store = tx.objectStore('auth');
      
      const data = {
        id: 'authData', // Use a fixed ID so we always update the same record
        token,
        userData,
        timestamp: new Date().getTime()
      };
      
      const request = store.put(data);
      
      request.onsuccess = () => {
        logAuthDebug('IndexedDB: Auth data successfully written');
        resolve(true);
      };
      
      request.onerror = (event) => {
        const error = event.target.error;
        logAuthDebug('IndexedDB: Error during put operation', { error: error.message });
        reject(error);
      };
      
      tx.oncomplete = () => {
        logAuthDebug('IndexedDB: Transaction completed successfully');
        console.log('Auth data stored in IndexedDB');
      };
      
      tx.onerror = (event) => {
        const error = event.target.error;
        logAuthDebug('IndexedDB: Transaction error', { error: error.message });
        reject(error);
      };
    });
  } catch (err) {
    logAuthDebug('IndexedDB: Error storing auth data', { error: err.message, stack: err.stack });
    console.error('Error storing auth data in IndexedDB:', err);
    return false;
  }
};

/**
 * Retrieves authentication data from IndexedDB
 * @returns {Promise<Object|null>} The auth data or null if not found
 */
export const getAuthDataFromIndexedDB = async () => {
  logAuthDebug('IndexedDB: Attempting to retrieve auth data');
  
  try {
    const db = await openDatabase();
    logAuthDebug('IndexedDB: Database opened for reading auth data');
    
    return new Promise((resolve, reject) => {
      const tx = db.transaction('auth', 'readonly');
      const store = tx.objectStore('auth');
      
      const request = store.get('authData');
      
      request.onsuccess = () => {
        if (request.result) {
          // Avoid accessing specific fields that might not exist
          logAuthDebug('IndexedDB: Auth data found', { 
            hasToken: !!request.result.token,
            hasUserData: !!request.result.userData,
            timestamp: new Date(request.result.timestamp).toISOString()
          });
          
          // Return the data properly
          const data = {
            token: request.result.token,
            userData: request.result.userData,
            timestamp: request.result.timestamp
          };
          
          logAuthDebug('IndexedDB: Successfully retrieved auth data');
          console.log('Auth data retrieved from IndexedDB');
          
          resolve(data);
        } else {
          logAuthDebug('IndexedDB: No auth data found in store');
          resolve(null);
        }
      };
      
      request.onerror = (event) => {
        const error = event.target.error;
        logAuthDebug('IndexedDB: Error during get operation', { 
          error: error.message 
        });
        reject(error);
      };
      
      // Handle transaction completion errors
      tx.oncomplete = () => {
        // Transaction completed successfully (though by now we've already resolved)
      };
      
      tx.onerror = (event) => {
        logAuthDebug('IndexedDB: Transaction error', { 
          error: event.target.error.message 
        });
        reject(event.target.error);
      };
    });
  } catch (err) {
    logAuthDebug('IndexedDB: Error retrieving auth data', { error: err.message, stack: err.stack });
    console.error('Error retrieving auth data from IndexedDB:', err);
    return null;
  }
};

/**
 * Clears authentication data from IndexedDB
 * @returns {Promise<boolean>} Whether the operation succeeded
 */
export const clearAuthDataFromIndexedDB = async () => {
  logAuthDebug('IndexedDB: Attempting to clear auth data');
  
  try {
    const db = await openDatabase();
    logAuthDebug('IndexedDB: Database opened for clearing auth data');
    
    return new Promise((resolve, reject) => {
      const tx = db.transaction('auth', 'readwrite');
      const store = tx.objectStore('auth');
      
      const request = store.delete('authData');
      
      request.onsuccess = () => {
        logAuthDebug('IndexedDB: Delete operation successful');
        resolve(true);
      };
      
      request.onerror = (event) => {
        const error = event.target.error;
        logAuthDebug('IndexedDB: Error during delete operation', { 
          error: error.message 
        });
        reject(error);
      };
      
      tx.oncomplete = () => {
        logAuthDebug('IndexedDB: Auth data successfully cleared');
        console.log('Auth data cleared from IndexedDB');
      };
      
      tx.onerror = (event) => {
        const error = event.target.error;
        logAuthDebug('IndexedDB: Transaction error on delete', { 
          error: error.message 
        });
        reject(error);
      };
    });
  } catch (err) {
    logAuthDebug('IndexedDB: Error clearing auth data', { error: err.message, stack: err.stack });
    console.error('Error clearing auth data from IndexedDB:', err);
    return false;
  }
};