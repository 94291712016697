import React, { useState, useEffect, useCallback, useMemo  } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './calendar-components/ui/dialog';
import { Button } from './calendar-components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from './calendar-components/ui/popover';
import { Calendar } from './calendar-components/ui/calendar';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './calendar-components/ui/select';
import './calendar-styles.css';
import './dialog-styles.css';
import { useAuth } from '../../react-query/useAuth';
import { useEvents } from '../../react-query/useEvents';
import { format, startOfWeek, endOfWeek, isWithinInterval, isValid, isSameDay, parseISO } from 'date-fns';
import { split } from 'postcss/lib/list';
import { toast } from 'react-toastify';

// Helper function to get the safe class type
const getSafeClassType = (type) => {
  if (!type) return "other";
  return type.trim();
};






const classTypes = {
  crossfit: { name: 'CrossFit', color: 'bg-custom-red' },
  onRamp: { name: 'On Ramp', color: 'bg-custom-blue' },
  barbell: { name: 'Barbell', color: 'bg-custom-purple' },
  openGym: { name: 'Open Gym', color: 'bg-custom-green' },
  hero: { name: 'Hero', color: 'bg-custom-yellow' },
  other: { name: 'Other', color: 'bg-custom-orange' }
};

const coachNames = [
  'Jay', 'Jacob', 'Tom', 'Marni', 'Mirka', 'Craig', 'Matt', 'Jana',
  'Doza', 'Jonathan', 'Chris', 'Seth', 'Nick', 'Todd', 'Patrick', 'Ashlyn'
];

// Specify the time zone you're working with
const timeZone = 'America/New_York';

const ScheduleGrid = () => {

  const { userData } = useAuth();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(() => new Date());
  const [warningMessage, setWarningMessage] = useState('');
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [selectedCoach, setSelectedCoach] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [claimedSlots, setClaimedSlots] = useState({});
 // Calculate the start and end of the current week
//  const weekStart = format(startOfWeek(currentDate), 'yyyy-MM-dd');  // Format as YYYY-MM-DD
//  const weekEnd = format(endOfWeek(currentDate), 'yyyy-MM-dd');      // Format as YYYY-MM-DD


const [weekStart, setWeekStart] = useState(() => {
  try {
    const start = startOfWeek(currentDate);
    // console.log('Formated Value', format(start, 'yyyy-MM-dd'))
    return format(start, 'yyyy-MM-dd');
  } catch (error) {
    console.error('Error creating weekStart:', error);
    // Fallback to current date if there's an error
    return format(new Date(), 'yyyy-MM-dd');
  }
});

const [weekEnd, setWeekEnd] = useState(() => {
  try {
    const end = endOfWeek(currentDate);
    // console.log('Formated Value', format(end, 'yyyy-MM-dd'))
    return format(end, 'yyyy-MM-dd'); 
  } catch (error) {
    console.error('Error creating weekEnd:', error);
    // Fallback to 7 days from now if there's an error
    const sevenDaysLater = new Date();
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 6);
    return format(sevenDaysLater, 'yyyy-MM-dd');
  }
});

useEffect(() => {
  try {
    const start = startOfWeek(currentDate);
    const end = endOfWeek(currentDate);
    setWeekStart(format(start, 'yyyy-MM-dd'));
    // console.log('Formated Value Start', format(start, 'yyyy-MM-dd'))
    // console.log('Formated Value End', format(end, 'yyyy-MM-dd'))
    setWeekEnd(format(end, 'yyyy-MM-dd'));
  } catch (error) {
    console.error('Error updating week start/end:', error);
    // You might want to show an error message to the user here
  }
}, [currentDate]);


  // Update the useEvents hook to use the 'weekly' fetch type
  const { events, error, isLoading, dropClaimedEvent, claimCoachEvent } = useEvents({
    startDate: weekStart,
    endDate: weekEnd,
    fetchType: 'weekly', // Use weekly fetch type
  });


  const weekEvents = events?.events ?? [];
  const time_slots = events?.timeSlots ?? [];
  const exceptions = events?.exceptions ?? [];
  const starts = events?.starts ?? [];
  const stops = events?.stops ?? [];
  const available_events = events?.availableEvents ?? [];
  const weeklyTimeSlots = events?.weeklyTimeSlots ?? [];

const combinedEvents = useMemo(() => {
  // Just combine the events without normalization
  return [...available_events, ...weekEvents];
}, [JSON.stringify(available_events), JSON.stringify(weekEvents)]);

  
   // Function to initialize claimedSlots based on the fetched events
   const initializeClaimedSlots = useCallback((combinedEvents) => {
    const claimed = {};
    combinedEvents.forEach(event => {
      if (event.title) {
        const dateKey = event.start.split('T')[0];
        const timeKey = normalizeEventTime(event.start.split('T')[1]);
        claimed[`${dateKey}-${timeKey}`] = true;
      }
    });
    console.log(claimed)
    return claimed;
  }, []);

  useEffect(() => {
    if (combinedEvents) {
      setClaimedSlots(initializeClaimedSlots(combinedEvents));
    }
  }, [combinedEvents, initializeClaimedSlots]);


  // Convert 24-hour format to 12-hour format
  const convertTo12Hour = (time24h) => {
    const [hours, minutes] = time24h.split(':');
    const hour = parseInt(hours, 10);
    
    let period = 'AM';
    let hour12 = hour;
  
    if (hour === 0) {
      hour12 = 12;
    } else if (hour === 12) {
      period = 'PM';
    } else if (hour > 12) {
      hour12 = hour - 12;
      period = 'PM';
    }
  
    // Zero-pad the hour and minutes
    return `${hour12.toString().padStart(2, '0')}:${minutes} ${period}`;
  };

  // Add a new function for displaying time on the calendar
  // Updated formatDisplayTime function
  const formatDisplayTime = (time) => {
    const [timePart, period] = time.split(' ');
    let [hours, minutes] = timePart.split(':');
    
    // Remove leading zero from hours
    hours = parseInt(hours, 10).toString();
    
    // Use only the first letter of AM/PM
    const suffix = period.charAt(0);
    
    return `${hours}:${minutes}${suffix}`;
  };




  
  // Normalize time from 24-hour to 12-hour format with zero-padding
  const normalizeEventTime = (time24h) => {
    // Ensure the input time is properly formatted
    const [hours, minutes] = time24h.split(':').map(part => part.padStart(2, '0'));
    return convertTo12Hour(`${hours}:${minutes}`);
  };
  
  // Create sortedTimeSlots including times from monthEvents
  const sortedTimeSlots = useMemo(() => {
    const uniqueSlots = new Map();
    
    // Add times from weeklyTimeSlots
    weeklyTimeSlots.forEach(slot => {
      if (!uniqueSlots.has(slot.time)) {
        uniqueSlots.set(slot.time, { ...slot });
      } else {
        // Merge days if the time already exists
        const existingSlot = uniqueSlots.get(slot.time);
        existingSlot.days = [...new Set([...existingSlot.days, ...slot.days])];
      }
    });
  
    // Get the start and end of the current week
    const weekStart = startOfWeek(currentDate);
    const weekEnd = endOfWeek(currentDate);
  
    // Add times from monthEvents that fall within the current week
    combinedEvents.forEach(event => {
      try {
        
        const eventDate = new Date(event.start);
        
        if (!isValid(weekStart) || !isValid(weekEnd)) {
          console.error('Invalid week start or end:', { weekStart, weekEnd });
          return; // Skip interval check
        }
        
        if (isWithinInterval(eventDate, { start: weekStart, end: weekEnd })) {
          const eventTime = normalizeEventTime(event.start.split('T')[1]);
          if (event)
            // const eventTime = normalizeEventTime(event.start.split('T')[1], event.start.split('T')[0], eventTimeZone);
          if (!uniqueSlots.has(eventTime)) {
            uniqueSlots.set(eventTime, {
            time: eventTime,
            type: event.type || 'other',
            days: [format(eventDate, 'EEEE')] // Add the day of the week
          });
        } else {
          // Add the day to existing slot if it's not already there
          const existingSlot = uniqueSlots.get(eventTime);
          const dayOfWeek = format(eventDate, 'EEEE');
          if (!existingSlot.days.includes(dayOfWeek)) {
            existingSlot.days.push(dayOfWeek);
          }
        }
      }
    } catch (error) {
      console.error("Error Processing Event:", event, error)
    }
    });
  
    // Convert to array, sort, and return
    return Array.from(uniqueSlots.values()).sort((a, b) => {
      return new Date('1970/01/01 ' + a.time) - new Date('1970/01/01 ' + b.time);
    });
  }, [weeklyTimeSlots, weekEvents, combinedEvents, currentDate, normalizeEventTime]);

  const weekDays = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => {
      const day = new Date(currentDate);
      day.setDate(day.getDate() - day.getDay() + i);
      // Reset time to midnight to ensure each day starts at 00:00
      day.setHours(0, 0, 0, 0);
      console.log(`Day ${i}:`, day.toISOString());
      return day;
    });
  }, [currentDate.getTime()]); // Use timestamp instead of Date object
  
  const formatDate = (date) => {
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    return `${days[date.getDay()]} ${date.getDate()}`;
  };

  const handlePrevWeek = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 7);
      return newDate;
    });
  };

  const handleNextWeek = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 7);
      return newDate;
    });
  };

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };


  const renderDay = (day, _selectedDays, dayProps) => {
    console.log('Rendering day:', day, dayProps);
    console.log('Rendering dayProps:', dayProps);
    return (
      <div {...dayProps} onClick={() => console.log('Day clicked:', day)}>
        {format(day, 'd')}
      </div>
    );
  };


  const handleCalendarSelect = (date) => {
    console.log('Calendar date selected:', date);
    if (isValid(date)){
      setCalendarDate(date);
      setCurrentDate(date);
      console.log('Updateed CurrentDate:', date)
    } else {
      console.error('Invlaid Date Selected:', date)
    }
  };

  
  const handleCellClick = async (day, slot, event) => {
    console.log('Handle Claim Test Start');
    console.log(day, slot);
    console.log(event);
    console.log('Handle Claim Test Stop');

    const available = isAvailable(day, slot);
    if (!available) {
      toast.error(`This slot is not available for ${formatDate(day)}.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    } 
    
    if (event && event.title && event.title !== userData.first_name && !userData.is_admin) {
      toast.error(`This is not your class to unclaim.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    
    setWarningMessage('');
    setSelectedSlot({ day, slot, event });
    setIsDialogOpen(true);
    if (!userData.is_admin) {
      setSelectedCoach(userData.first_name); // Automatically set coach name for regular users
    }
  
  };


  
  const handleClaimSlot = async () => {
    if (selectedSlot && selectedCoach) {
      const { day, slot, event } = selectedSlot;
      const startTime = event ? event.start : day.toISOString().split('T')[0] + 'T' + slot.time; // Use event start time if available
      // Calculate endTime: If event exists, use event.end; otherwise, add one hour to startTime
      const endTime = event 
      ? event.end 
      : new Date(new Date(startTime).getTime() + 60 * 60 * 1000).toISOString(); // Add 1 hour to startTime


      try {
        // Get normalized class type
        const safeClassType = event ? getSafeClassType(event.type) : "other";
        await claimCoachEvent(safeClassType, event?.recurring || false, startTime, endTime, selectedCoach, event?._id);
        setClaimedSlots(prev => ({
          ...prev,
          [`${day.toISOString().split('T')[0]}-${slot.time}`]: true
        }));
      } catch (error) {
        // toast.error('Failed to claim the slot.');
        console.error('Error claiming slot:', error);
      }
      setIsDialogOpen(false);
    }
    else{
      console.log(selectedCoach)
      console.log(selectedSlot)
    }
  };
  
  const handleUnclaimSlot = async () => {
    if (selectedSlot) {
      const { event } = selectedSlot;
      if (event && event._id) {
        const month = new Date(event.start).getMonth() + 1;
        const year = new Date(event.start).getFullYear();
        try {
          await dropClaimedEvent(event, month, year);
          setClaimedSlots(prev => {
            const newClaimedSlots = { ...prev };
            delete newClaimedSlots[`${event.start.split('T')[0]}-${normalizeEventTime(event.start.split('T')[1])}`];
            return newClaimedSlots;
          });
          toast.success('Slot unclaimed successfully.');
        } catch (error) {
          toast.error('Failed to unclaim the slot.');
          console.error('Error unclaiming slot:', error);
        }
        setIsDialogOpen(false);
      }
    }
  };


  const isAvailable = useCallback((day, slot) => {
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = dayNames[day.getDay()];
    return slot.days.includes(dayName);
  }, []);

  useEffect(() => {
    // console.log('monthEvents:', monthEvents);
    // console.log('sortedTimeSlots:', sortedTimeSlots);
    // console.log('userData:', userData);

    // Log details of all events
    combinedEvents.forEach(event => {
        if (event.start.split('T')[0] == '2024-11-03'){
           
        }
        if (event._id == '66d0ae59f13427a1a840fe45'){
        
        }
    });

  }, [weekEvents, sortedTimeSlots]);

  return (
    <div className="flex flex-col h-screen bg-gray-100 text-xs">
      <div className="flex justify-between items-center p-2 bg-white shadow">
        <button onClick={handlePrevWeek} className="p-1">
          <ChevronLeft size={16} />
        </button>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" className="text-sm font-bold">
              {`${weekDays[0].toLocaleDateString()} - ${weekDays[6].toLocaleDateString()}`}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              mode="single"
              selected={calendarDate}
              onSelect={handleCalendarSelect}
              initialFocus
            />
          </PopoverContent>
        </Popover>
        <button onClick={handleNextWeek} className="p-1">
          <ChevronRight size={16} />
        </button>
      </div>
      <div className="p-1 bg-white shadow flex flex-wrap justify-center">
        {Object.entries(classTypes).map(([key, value]) => (
          <div key={key} className="flex items-center mr-2 mb-1">
            <div className={`w-2 h-2 ${value.color} rounded mr-1`}></div>
            <span className="text-xxs">{value.name}</span>
          </div>
        ))}
      </div>
      <div className="flex-grow overflow-hidden flex flex-col">
        <div className="flex-grow overflow-auto">
          <table className="w-full h-full border-collapse table-fixed">
            <thead>
              <tr>
                <th className="w-16 sticky top-0 left-0 z-20 bg-white"></th>
                {weekDays.map(day => (
                  <th key={day.toISOString()} className={`sticky top-0 z-10 p-1 text-center ${isToday(day) ? 'bg-blue-100' : 'bg-white'}`}>
                    {formatDate(day)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedTimeSlots.map(slot => (
                <tr key={slot.time}>
                  <td className="sticky left-0 z-10 bg-white border-r p-1 text-center">{formatDisplayTime(slot.time)}</td>
                  {weekDays.map(day => {
                    const dateKey = day.toISOString().split('T')[0];
                    const cellKey = `${dateKey}-${slot.time}`;
                    const event = combinedEvents.find(e => {
                      const eventDate = new Date(e.start);
                      const eventDateString = eventDate.toISOString().split('T')[0];
                      
                      // const localDateString = format(localStartDate, 'yyyy-MM-dd');
                      const eventMatches = eventDateString  === dateKey &&
                        normalizeEventTime(e.start.split('T')[1]) === slot.time;
                      return eventMatches;
                    });

                      // Get normalized class type
                      const eventType = event ? getSafeClassType(event.type) : "";
                      const backgroundColor = event && !event.title && !claimedSlots[cellKey] ? classTypes[eventType]?.color || classTypes.other.color : 'bg-white';

                    return (
                      <td
                        key={`${day.toISOString()}-${slot.time}`}
                        data-custom={`${day.toISOString()}-${slot.time}`}
                        // className={`border p-1 ${isToday(day) ? 'bg-blue-50' : ''} cursor-pointer`}
                        // className={`border p-1 ${isToday(day) ? 'bg-blue-50' : ''} cursor-pointer ${backgroundColor}`}
                        className={`border p-1 cursor-pointer ${backgroundColor}`}
                        onClick={() => handleCellClick(day, slot, event)}
                      >
                        {event && event.title === "" ? (
                            <div className="text-center text-xs text-gray-500">Open</div> // Display "Open" if event title is empty
                          ) : event ? (
                            <div
                              className={`${classTypes[eventType]?.color || classTypes.other.color} rounded text-center overflow-hidden whitespace-nowrap text-2xs`}
                              title={`${event.title} - ${classTypes[eventType]?.name || 'Other'}`}
                            >
                              {event.title}
                            </div>
                          ) : null}
                        </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent
          className="dialog-content"
          style={{ overflowY: 'auto', marginLeft: '38px' }}
        >
          <DialogHeader>
            <DialogTitle>{selectedSlot ? `${formatDate(selectedSlot.day)} - ${selectedSlot.slot.time}` : ''}</DialogTitle>
          </DialogHeader>
          {userData.is_admin ? (
            selectedSlot && selectedSlot.event && selectedSlot.event.title ? (
              <div className="flex flex-col items-center">
                <p>This slot is claimed by: {selectedSlot.event.title}</p>
                <Button onClick={handleUnclaimSlot} className="mt-2" variant="destructive">Unclaim Slot</Button>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <Select onValueChange={setSelectedCoach} value={selectedCoach}>
                  <SelectTrigger className="w-full mb-4">
                    <SelectValue placeholder="Select a coach" />
                  </SelectTrigger>
                  <SelectContent>
                    {coachNames.map((coach) => (
                      <SelectItem key={coach} value={coach}>
                        {coach}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button onClick={handleClaimSlot} disabled={!selectedCoach}>Claim Slot</Button>
              </div>
            )
          ) : (
            selectedSlot && selectedSlot.event && selectedSlot.event.title ? (
              <div className="flex flex-col items-center">
                <p>You have claimed this slot. Do you want to unclaim it?</p>
                <Button onClick={handleUnclaimSlot} className="mt-2" variant="destructive">Unclaim Slot</Button>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <p>Do you want to claim this slot?</p>
                <Button onClick={handleClaimSlot}>Claim Slot</Button>
              </div>
            )
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};


export default ScheduleGrid;