import React, { useState, useEffect } from 'react';
import styles from '../stylez/UserSelector.module.css';

// Default profile image as a base64 string or URL
const DEFAULT_PROFILE_IMAGE = '/pace_152x152_fav.png';

function UserSelector({ users, onSelectionChange, disabled }) {
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Reset selected users when the users list changes
  useEffect(() => {
    setSelectedUsers([]);
  }, [users]);

  const toggleUserSelection = (userId) => {
    setSelectedUsers(prevSelectedUsers => {
      const newSelection = prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter(id => id !== userId)
        : [...prevSelectedUsers, userId];
      
      // Call the parent component's callback with the new selection
      onSelectionChange(newSelection);
      return newSelection;
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>Select Recipients</h4>
        <div className={styles.selectionInfo}>
          {selectedUsers.length} of {users.length} selected
        </div>
      </div>
      
      <div className={styles.userList}>
        {users.length === 0 ? (
          <div className={styles.noUsers}>
            No users have subscribed to notifications yet.
          </div>
        ) : (
          users.map(user => {
            const isEnabled = user.subscription_count > 0;
            const isSelected = selectedUsers.includes(user.user_id);
            
            return (
              <div 
                key={user.user_id}
                className={`${styles.userCard} ${!isEnabled ? styles.disabled : ''} ${isSelected ? styles.selected : ''}`}
                onClick={() => isEnabled && !disabled && toggleUserSelection(user.user_id)}
              >
                <div className={styles.avatar}>
                  <img 
                    src={user.profile_pic || DEFAULT_PROFILE_IMAGE} 
                    alt={user.user_name} 
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = DEFAULT_PROFILE_IMAGE;
                    }}
                  />
                </div>
                <div className={styles.userInfo}>
                  <div className={styles.userName}>{user.user_name}</div>
                  <div className={styles.userEmail}>{user.email}</div>
                </div>
                {isEnabled ? (
                  <div className={styles.subscriptionStatus}>
                    {user.subscription_count} device{user.subscription_count !== 1 ? 's' : ''}
                  </div>
                ) : (
                  <div className={styles.notSubscribed}>Not subscribed</div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default UserSelector;