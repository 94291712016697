import { dataInstance } from './axiosInstance';


export const fetchWeeklyTimeSlots = async () => {
  try {
    const response = await dataInstance.get('/events/weekly-time-slots');
    return response.data;
  } catch (error) {
    console.error('Error fetching weekly time slots:', error);
    throw error;
  }
};


export const fetchEventsForMonth = async ({ month, year }) => {
    const { data } = await dataInstance.get('/events/events-for-month', {
      params: {
        month,
        year,
      },
    });
    return data;
  };


export const fetchEventsForBiWeekly = async ({startDate, endDate}) => {
    try {
      const response = await dataInstance.get('/events/events-in-range', {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      return response.data;
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
    }
  };

export const fetchEventsForCoachesBiWeekly = async ({startDate, endDate}) => {
  try {
    const response = await dataInstance.get('/events/events-in-range-by-coach', {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching data: ', error);
  }
};

export const fetchNxtEvent = async () => {
  try {
    const response = await dataInstance.get('/events/next-event');
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching the next event:', error);
    throw error;
  }
};

export const fetchEventsForSingleDay = async ({ date_str }) => {
  try {
    console.log('fetching events for date:', date_str)
    const response = await dataInstance.get('/events/events-for-date', {
      params: {
        date_str: date_str, // This needs to match the backend's expected query parameter name
      },
    });
    console.log('response', response.data)
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching events for the date:', error);
    throw error;
  }
};




export async function createComment(commentData) {
  try {
    const response = await dataInstance.post('/comments', commentData);
    return response.data;
  } catch (error) {
    console.error(`Error creating comment: ${error}`);
    return null;
  }
}

export async function readComments(date) {
  try {
    const response = await dataInstance.get('/comments', {
      params: {
        date: date
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error reading comments: ${error}`);
    return null;
  }
}

// axios function to delete a comment
export async function deleteComment(commentId) {
  try {
    const response = await dataInstance.delete(`/comments/${commentId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting comment: ${error}`);
    return null;
  }
}

export async function updateComment({id, updatedText}) {
  try {
    console.log('trying', updatedText)
    const response = await dataInstance.put(`/comments/${id}`, updatedText);
    return response.data;
  } catch (error) {
    console.error(`Error updating comment: ${error}`);
    return null;
  }
}

export const fetchImage = async (filename) => {
  try {
    const response = await dataInstance.get(`/images/${filename}`, { responseType: 'arraybuffer' });
    const base64 = btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    return `data:image/jpeg;base64,${base64}`;
  } catch (error) {
    console.error('There was a problem fetching the image:', error);
  }
};

export const sendFeatureRequest = async ({title, body}) => {
  try {
      const response = await dataInstance.post('/feature-requests', {
          title,
          body,
      });

      
      // if (response.status !== 201) {
//   throw new Error('Request failed with status ' + response.status);
      // }
          return response.data;
        } catch (error) {
            console.error('Failed to send feature request:', error);
      throw error; // Re-throw the error to ensure it propagates
  }
};

export const getFeatureRequests = async () => {
  try {
    const response = await dataInstance.get('/feature-requests');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch feature requests:', error);
    throw error;
  }
};

// api_data.js

// Function to delete a feature request
export const deleteFeatureRequest = async (featureRequestId) => {
  try {
    const response = await dataInstance.delete(`/feature-requests/${featureRequestId}`);
    return response.data; // Assuming the response contains some relevant data upon successful deletion
  } catch (error) {
    console.error(`Error deleting feature request: ${error}`);
    throw error;
  }
};


// Fetch the daily video link
export const fetchDailyVideo = async () => {
  const { data } = await dataInstance.get('/daily-prog');
  return data;
};

// Fetch the weekly PDF link
export const fetchWeeklyPDF = async () => {
  try {
    const response = await dataInstance.get('/wods-pdf', { responseType: 'blob' });
    const pdfUrl = URL.createObjectURL(response.data);
    return { pdf_url: pdfUrl };
  } catch (error) {
    throw new Error('Failed to fetch weekly PDF');
  }
};

// axios function to attach a user to a time slot
export async function attachUserToTimeSlot(timeSlotId) {
  try {
    const response = await dataInstance.post(`/time-slots/${timeSlotId}/attach`);
    return response.data;
  } catch (error) {
    console.error(`Error attaching user to time slot: ${error}`);
    return null;
  }
}

// axios function to detach a user from a time slot
export async function detachUserFromTimeSlot(timeSlotId) {
  try {
    const response = await dataInstance.post(`/time-slots/${timeSlotId}/detach`);
    return response.data;
  } catch (error) {
    console.error(`Error detaching user from time slot: ${error}`);
    return null;
  }
}


export const fetchEventsForWeek = async ({ startDate, endDate }) => {
  try {
    const response = await dataInstance.get('/events/events-for-week', {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching weekly events:', error);
    throw error;
  }
};



// axios function to fetch all time slots using post /get-time-slots
export async function fetchTimeSlots(current_date, day_count) {
  try {
    const response = await dataInstance.get('/events/get-time-slots', {
      params: {
        current_date,
        day_count
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching time slots: ${error}`);
    return null;
  }
}

// axios function to claim an event
export const claimEvent = async ({classType, recurring, startTime, endTime, firstName, id = null}) => {
  try {
    const eventData = {
      class_type: classType,
      recurring,
      start_time: startTime,
      end_time: endTime,
      coach_name: firstName,  // Add the firstName as coach_name in the payload
      id
    };
    const response = await dataInstance.post('/events/claim-event', eventData);
    return response.data;
  } catch (error) {
    console.error(`Error claiming event: ${error}`);
    return null;
  }
};


export async function dropEvent({cur_el, month, year}) {
  try {
    const response = await dataInstance.delete(`/events/unclaim-event/${cur_el._id}`);
    return response.data;
  } catch (error) {
    console.error(`Error unclaiming event: ${error}`);
    return null;
  }
}

export async function deleteEvent({ eventObj, all }) {
  try {
    let url;
    const idOrTime = eventObj._id || eventObj.start; // Use _id if available, otherwise use start time

    if (all) {
      // If 'all' is true, delete all events (either with the same _id or startTime)
      url = `/events/delete-all`;
    } else {
      // Otherwise, delete the specific event
      url = `/events/delete-event`;
    }

    const response = await dataInstance.delete(url, {
      params: {
        startTime: eventObj.start,
        class_type: eventObj.type,
        _id: eventObj._id // This will be undefined if _id is not available
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error deleting event: ${error}`);
    return null;
  }
}

export const createScheduleEvent = async (scheduleData) => {
  const { classType, isRecurring, startTime, endTime, date, nxt, selectedDayNames  } = scheduleData;
  try {
    const response = await dataInstance.post('/events/create-schedule', {
      class_type:classType,
      recurring:isRecurring,
      startTime,
      endTime,
      date, 
      nxt,
      days: selectedDayNames.length > 0 ? selectedDayNames : []
    });
    console.log(response.data); // Handle the response data as needed
    return response.data;
  } catch (error) {
    console.error('Error creating schedule:', error.response?.data || error.message);
    throw error;
  }
};



export const getUsers = async () => {
  try {
    const response = await dataInstance.get('/admin/get-users');
    return response.data;
  } catch (error) {
    // Handle error here if needed or rethrow to be handled by the calling function
    throw error;
  }
};

// export const verifyAdmin = async () => {
//   try {
//     const response = await dataInstance.get('/admin/verify');
//     // return response.status; // Returns the HTTP status code
//     if (response.status === 200) {
//       return 'valid';
//     }else{
//       return 'invalid';
//     }
//   } catch (error) {
//     if (error.response) {
//       // throw new Error(`Verification failed: ${error.response.status}`);
//       return 'invalid';
//     } else {
//       // throw new Error('Network or server error');
//       return 'invalid';
//     }
//   }
// };

// Function to make a user admin
export const makeUserAdmin = async (userId, isAdmin) => {
  try {
    const response = await dataInstance.post('/admin/make-user-admin', {
      user_id: userId,
      is_admin: isAdmin,
    });
    return response.data; // Contains the response from the server
  } catch (error) {
    // Handle any errors here
    console.error('Error making user admin:', error.response || error);
    throw error;
  }
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await dataInstance.post('/admin/ui-upload-programming-materials', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;

};


export const updateDailyVideo = async ({ day, video_link }) => {                                                                                                                
  try {                                                                                                                                                                         
    const response = await dataInstance.post('/admin/update-daily-video', {                                                                                                     
      day,                                                                                                                                                                      
      video_link                                                                                                                                                                
    });                                                                                                                                                                         
    return response.data;                                                                                                                                                       
  } catch (error) {                                                                                                                                                             
    console.error('Error updating daily video:', error);                                                                                                                        
    throw error;                                                                                                                                                                
  }                                                                                                                                                                             
};                                            