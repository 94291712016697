import {BrowserRouter as Router, Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import Home from './pages/HomePage';
import Vacations from './pages/VacationsPage';
import HoursWorked from './pages/HoursWorkedPage';
import Settings from './pages/SettingsPage';
import Schedule from './pages/SchedulePage';
import Sidebar from './components/HomeComponents/SideBar';
import SignInPage from './pages/SigninPage';
import ProtectedRoute from './components/ProtectedRoutes';
import { useEffect, useState } from 'react';
import SignUpPage from './pages/SignupPage';
// import ScheduleMaker from './pages/ScheduleMakerPage';
import UpdateDetails from './components/SettingsComponents/UpdateDetails';
import FeatureRequest from './components/SettingsComponents/FeatureReq';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NewSideBar from './components/HomeComponents/NewSideBar';
import AdminSidebar from './components/AdminComponents/AdminSidebar';
import AdminPage from './pages/AdminPage';
import UsersPage from './pages/UsersPage';
import UserRequestsPage from './pages/UserRequestsPage';
import MobileOnlyPage from './pages/MobileOnlyPage';
import TestSignInPage from './pages/TestSignin';
import AddClassPage from './pages/AddClassPage';
import ProgUpload from './pages/ProgUploadPage';
import { useAuth } from './react-query/useAuth';
import ScheduleGrid from './components/GridCalendar/ScheduleGrid';
import { refreshToken, verifyToken, restoreAuthFromIndexedDB } from './services/api_auth';
import NotificationTestPage from './pages/NotificationTestPage';
import AppDebugPage from './pages/NotificationDebugPage';


function Layout() {
  const location = useLocation();
  const nav = useNavigate();
  
  const {userData} = useAuth();
  // console.log('app determins admin', userData.is_admin)
  const isAdminRoute = location.pathname.startsWith('/admin');

  const hideSidebarForRoutes = ["/signin", "/signup", "/desktop", '/tsignin'];
  const routesWithBackground = ["/signin", "/signup", "/signout"]; // Add "/signout" if it exists

  const [isMobileDevice, setIsMobileDevice] = useState(isMobile()); // Track mobile device state

  
  // Handle service worker messages for notification navigation (iOS PWA)
  useEffect(() => {
    const handleServiceWorkerMessages = (event) => {
      // Log message for debugging
      console.log("[App] Service worker message received:", event.data);
      
      // Check if this is a navigation message from notification click
      if (event.data && event.data.type === "NOTIFICATION_NAVIGATE") {
        const path = event.data.path || "/";
        console.log("[App] Navigating to path from notification:", path);
        console.log("[App] Full navigation data:", JSON.stringify(event.data));
        
        // iOS PWA fix: Force a more direct approach for iOS
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                       window.navigator.standalone === true;
        
        // Create a debug message for visible logging
        const debugInfo = {
          deviceInfo: { isIOS, isPWA, userAgent: navigator.userAgent.substring(0, 50) + "..." },
          targetPath: path,
          messageData: event.data,
          timestamp: new Date().toISOString()
        };
        
        // Save debug info to localStorage for later retrieval
        try {
          const logs = JSON.parse(localStorage.getItem('notification_nav_logs') || '[]');
          logs.push(debugInfo);
          // Keep only last 10 logs
          while (logs.length > 10) logs.shift();
          localStorage.setItem('notification_nav_logs', JSON.stringify(logs));
        } catch (e) {
          console.error('Error saving debug logs:', e);
        }
                
        console.log("[App] Environment:", { isIOS, isPWA, path });
        
        // Show detailed debug toast for iOS devices
        if (isIOS) {
          toast.info(`Notification Nav: iOS=${isIOS}, PWA=${isPWA}, Path=${path}`);
        }
        
        // Use navigate function from React Router to change routes
        try {
          // For iOS PWA, use a more immediate approach with longer delay
          if (isIOS && isPWA) {
            console.log("[App] Using iOS PWA specific navigation");
            
            // Show toast immediately so user knows something is happening
            toast.info("iOS: Opening notification destination...");
            
            // Use a longer delay for iOS to ensure app is ready
            setTimeout(() => {
              console.log("[App] Executing delayed navigation to:", path);
              // Show another toast before the redirect
              toast.success(`iOS: Redirecting to ${path}...`);
              
              // Try 3 approach for iOS
              try {
                // Approach 1: Direct location change
                window.location.href = window.location.origin + path;
              } catch (err) {
                console.error('Direct navigation failed:', err);
                toast.error('Navigation method 1 failed, trying method 2...');
                
                // Approach 2: Try with replace state
                try {
                  window.history.replaceState(null, '', path);
                  nav(path, { replace: true });
                } catch (err2) {
                  console.error('Method 2 failed:', err2);
                  toast.error('Method 2 failed. Check debug page.');
                  
                  // Save this error to localStorage
                  localStorage.setItem('notification_nav_error', JSON.stringify({
                    error1: err.toString(),
                    error2: err2.toString(),
                    path,
                    timestamp: new Date().toISOString()
                  }));
                }
              }
            }, 800);
          } else {
            // Normal navigation for other platforms
            setTimeout(() => {
              nav(path);
              toast.info("Opening notification destination");
            }, 100);
          }
        } catch (error) {
          // Show a visible error for debugging
          console.error("[App] Error navigating to notification path:", error);
          toast.error("Navigation error: " + error.message);
          
          // Save error details to localStorage
          localStorage.setItem('notification_nav_error', JSON.stringify({
            error: error.toString(),
            path,
            timestamp: new Date().toISOString()
          }));
        }
      }
      
      // Handle other service worker log messages
      if (event.data && event.data.type === "SW_LOG") {
        console.log(`[ServiceWorker Log] ${event.data.message}`);
      }
    };

    // Add event listener for messages from service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", handleServiceWorkerMessages);
    }
    
    // Clean up event listener on component unmount
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", handleServiceWorkerMessages);
      }
    };
  }, [nav]);

       // 1. Visibility change effect - detects when app comes back from background
       useEffect(() => {
        // Handle app resuming from background
        const handleVisibilityChange = async () => {
          if (document.visibilityState === 'visible') {
            console.log('App resumed from background');

            // Log to pwa_notification_logs with [Visibility] prefix
      try {
        const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
        logs.push({
          timestamp: new Date().toISOString(),
          message: "[Visibility] App resumed from background"
        });
        localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
      } catch (e) {
        console.error("Error logging visibility change:", e);
      }
  
            // Check if user was authenticated
            const isAuthenticated = !!localStorage.getItem('accessToken');

            // Log authentication status
      try {
        const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
        logs.push({
          timestamp: new Date().toISOString(),
          message: `[Visibility] Authentication status: ${isAuthenticated ? 'authenticated' : 'not authenticated'}`
        });
        localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
      } catch (e) {
        console.error("Error logging auth status:", e);
      }
  
            if (isAuthenticated) {
              // Try to restore from IndexedDB if localStorage data is missing
              const restored = await restoreAuthFromIndexedDB();

              // Log restoration result
        try {
          const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
          logs.push({
            timestamp: new Date().toISOString(),
            message: `[Visibility] IndexedDB check completed`
          });
          localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
        } catch (e) {
          console.error("Error logging IndexedDB restoration:", e);
        }
  
              // Verify token is still valid
              const isValid = await verifyToken();

              // Log token validation result
        try {
          const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
          logs.push({
            timestamp: new Date().toISOString(),
            message: `[Visibility] Token validation result: ${isValid ? 'valid' : 'invalid'}`
          });
          localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
        } catch (e) {
          console.error("Error logging token validation result:", e);
        }

              if (!isValid) {
                // Token is invalid, try to refresh
                try {
                  // Log refresh attempt
            try {
              const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
              logs.push({
                timestamp: new Date().toISOString(),
                message: "[Visibility] Initiating visibility-triggered token refresh"
              });
              localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
            } catch (e) {
              console.error("Error logging refresh attempt:", e);
            }
                  await refreshToken();

                  try {
                    const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
                    logs.push({
                      timestamp: new Date().toISOString(),
                      message: "[Visibility] Visibility-triggered token refresh SUCCESS"
                    });
                    localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
                  } catch (e) {
                    console.error("Error logging refresh success:", e);
                  }

                } catch (error) {
                  console.error('Failed to refresh token on resume:', error);
                  // Log refresh failure
                  try {
                    const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
                    logs.push({
                      timestamp: new Date().toISOString(),
                      message: `[Visibility] Visibility-triggered token refresh FAILED: ${error.message}`
                    });
                    localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
                  } catch (e) {
                    console.error("Error logging refresh failure:", e);
                  }
                  // Will redirect to login automatically if refresh fails completely


                }
              }
            }
          }
        };
  
        document.addEventListener('visibilitychange', handleVisibilityChange);
  
        // Clean up event listener on unmount
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, []);
  
      // 2. Periodic token refresh effect
      useEffect(() => {
        const isAuthenticated = !!localStorage.getItem('accessToken');
  
        if (isAuthenticated) {
          // Refresh token proactively every 15 minutes
          const refreshInterval = 15 * 60 * 1000; // 15 minutes
  
          const tokenRefreshTimer = setInterval(async () => {
            // Log to debug storage
            try {
              const logs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
              logs.push({
                timestamp: new Date().toISOString(),
                message: "[Auth] Proactively refreshing token"
              });
              localStorage.setItem("pwa_notification_logs", JSON.stringify(logs));
            } catch (e) {
              console.error("Error logging token refresh:", e);
            }
            console.log('Proactively refreshing token');
            try {
              await refreshToken();
            } catch (error) {
              // Log the error
              try {
                const errorLogs = JSON.parse(localStorage.getItem("pwa_notification_logs") || "[]");
                errorLogs.push({
                  timestamp: new Date().toISOString(),
                  message: `[Auth] Periodic token refresh error: ${error.message}`
                });
                localStorage.setItem("pwa_notification_logs", JSON.stringify(errorLogs));
              } catch (e) {
                console.error("Error logging refresh error:", e);
              }
              console.error('Proactive token refresh failed:', error);
            }
          }, refreshInterval);
  
          return () => {
            clearInterval(tokenRefreshTimer);
          };
        }
      }, []);


      // Update the mobile device state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(isMobile());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Set the body background image based on the route
  useEffect(() => {
    if (routesWithBackground.includes(location.pathname)) {
      document.body.style.backgroundImage = "url('/coach-mobile-box.png')"; // Replace with your image path
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundPosition = "center center";
      document.body.style.backgroundSize = "cover";
      document.body.style.backgroundAttachment = "fixed";
    } else {
      document.body.style.backgroundImage = "none";

    // Check for debug flag in URL (allows accessing debug outside login)\n    if (location.search.includes('debug=notification')) {\n      // Redirect to debug page with access flag\n      localStorage.setItem('debug_access_direct', 'triggered_from_url');\n      nav('/notifications/debug');\n    }
    }
  
    // Check for app debug flag in URL (allows accessing debug outside login)
    if (location.search.includes('debug=app')) {
      // Redirect to debug page with access flag
      localStorage.setItem('debug_access_direct', 'triggered_from_url');
      nav('/notifications/debug');
    }

    }, [location.pathname]);

    // Function to determine if the user is on a mobile device based on screen width
    function isMobile() {
      return window.innerWidth <= 768; // Adjust the threshold as needed
    }

  // const toastOptions = {
  //   toastStyle: {
  //     width: '75%', // Adjust the width as needed
  //     marginLeft: 'auto',
  //     marginRight: 'auto',
  //   },
  // };
  
  // toast.configure(toastOptions);

  return (
    <div className={`d-flex ${isMobileDevice ? 'mobile' : 'desktop'}`} style={{overflowX:"hidden"}}>
      {/* Always show sidebar except for specific routes */}
    {!hideSidebarForRoutes.includes(location.pathname) && (
        isAdminRoute ? <AdminSidebar /> : <NewSideBar isAdmin={userData?.is_admin} />
      )}
      <div className="flex-grow-1" style={hideSidebarForRoutes.includes(location.pathname) ? {} : {marginLeft:"55px", maxWidth:"100%"}}>
        {/* {isMobileDevice || isAdminRoute || routesWithBackground ? ( */}
          <Routes>
              <Route path="/signin" element={<SignInPage/>} />
              {/* <Route path="/tsignin" element={<TestSignInPage/>} /> */}
              {/* <Route path="/signup" element={<SignUpPage/>} /> */}
              <Route path="/desktop" element={<MobileOnlyPage/>} />
              <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>} />
              <Route path="/vacations" element={<ProtectedRoute><Vacations/></ProtectedRoute>} />
              <Route path="/settings" element={<ProtectedRoute><Settings/></ProtectedRoute>} />
              <Route path="/update" element={<ProtectedRoute><UpdateDetails/></ProtectedRoute>} />
              <Route path="/request" element={<ProtectedRoute><FeatureRequest/></ProtectedRoute>} />
              <Route path="/schedule" element={<ProtectedRoute><Schedule/></ProtectedRoute>} />
              <Route path="/schedule-grid" element={<ProtectedRoute><ScheduleGrid/></ProtectedRoute>} />
              {/* <Route path="/schedule/maker" element={<ProtectedRoute><ScheduleMaker/></ProtectedRoute>} /> */}
              <Route path="/schedule/maker" element={<ProtectedRoute><AddClassPage/></ProtectedRoute>} />
              <Route path="/hours-worked" element={<ProtectedRoute><HoursWorked/></ProtectedRoute>} />
              <Route path="/admin/panel" element={<ProtectedRoute><AdminPage/></ProtectedRoute>} />
              <Route path="/admin/users" element={<ProtectedRoute><UsersPage/></ProtectedRoute>} />
              <Route path="/admin/requests" element={<ProtectedRoute><UserRequestsPage/></ProtectedRoute>} />
              <Route path="/admin/programming-upload" element={<ProtectedRoute><ProgUpload/></ProtectedRoute>} />
              <Route path="/notifications/test" element={<ProtectedRoute><NotificationTestPage /></ProtectedRoute>} />
              <Route path="/notifications/debug" element={<ProtectedRoute><AppDebugPage /></ProtectedRoute>} />
              
            </Routes>
        {/* ) : ( */}
          {/* <div className="desktop-message"> */}
            {/* <p>This is a mobile-only app.</p> */}
          {/* </div> */}
        {/* )} */}

      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Layout />
      <ToastContainer position="top-right" autoClose={5000} style={{width:'75%', marginLeft:"auto"}}/>
    </Router>
  );
}

export default App;