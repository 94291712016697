import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Alert, Badge } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../react-query/useAuth';
import { getAuthDebugLogs, clearAuthDebugLogs } from '../functions/AuthDebug';
import { refreshToken } from '../services/api_auth';
import { getAuthDataFromIndexedDB, storeAuthDataInIndexedDB } from '../services/indexedDBService';
import { authInstance } from '../services/axiosInstance';

const AppDebugPage = () => {
  const [navLogs, setNavLogs] = useState([]);
  const [navError, setNavError] = useState(null);
  const [pwaLogs, setPwaLogs] = useState([]);
  const [authLogs, setAuthLogs] = useState([]);
  const [userAgent, setUserAgent] = useState('');
  const [isIOS, setIsIOS] = useState(false);
  const [isPWA, setIsPWA] = useState(false);
  const [accessSource, setAccessSource] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [tokenStatus, setTokenStatus] = useState({
    hasLocalStorageToken: false,
    hasLocalStorageUserData: false,
    tokenFirstChars: '',
    userDataId: '',
    hasIndexedDBToken: false,
    indexedDBTokenFirstChars: '',
    indexedDBUserDataId: '',
  });
  
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useAuth();
  const isAdmin = userData?.is_admin === true;

  useEffect(() => {
    // Check how we accessed this page
    const directAccess = localStorage.getItem('debug_access_direct');
    const settingsAccess = localStorage.getItem('debug_access_flag');
    
    if (directAccess) {
      setAccessSource('direct_url');
    } else if (settingsAccess) {
      setAccessSource('settings_page');
    } else {
      setAccessSource('regular_navigation');
    }
    
    // Get notification navigation logs from localStorage
    try {
      const logs = JSON.parse(localStorage.getItem('notification_nav_logs') || '[]');
      setNavLogs(logs);
    } catch (e) {
      console.error('Error loading navigation logs from localStorage:', e);
    }
    
    // Get auth debug logs
    try {
      const logs = getAuthDebugLogs();
      setAuthLogs(logs);
    } catch (e) {
      console.error('Error loading auth debug logs:', e);
    }
    
    // Try to get notification logs from IndexedDB (where service worker stores data directly)
    try {
      const dbRequest = indexedDB.open('NotificationDebugDB', 1);
      
      dbRequest.onupgradeneeded = function(event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('notificationClicks')) {
          db.createObjectStore('notificationClicks', { autoIncrement: true });
        }
      };
      
      dbRequest.onsuccess = function(event) {
        const db = event.target.result;
        
        if (!db.objectStoreNames.contains('notificationClicks')) {
          console.log('No notification clicks object store found');
          return;
        }
        
        const transaction = db.transaction(['notificationClicks'], 'readonly');
        const store = transaction.objectStore('notificationClicks');
        const getAllRequest = store.getAll();
        
        getAllRequest.onsuccess = function() {
          const indexedDBLogs = getAllRequest.result || [];
          console.log('Retrieved logs from IndexedDB:', indexedDBLogs);
          
          if (indexedDBLogs.length > 0) {
            // If there are logs from IndexedDB, merge them with existing logs
            const formattedLogs = indexedDBLogs.map(log => ({
              timestamp: log.timestamp,
              messageData: log.data || {},
              targetPath: log.data?.navigationPath || log.data?.url || '/',
              deviceInfo: {
                isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent),
                isPWA: window.matchMedia('(display-mode: standalone)').matches || 
                       window.navigator.standalone === true,
                userAgent: navigator.userAgent.substring(0, 50) + '...'
              },
              source: 'IndexedDB'
            }));
            
            // Save to localStorage for future reference
            try {
              const existingLogs = JSON.parse(localStorage.getItem('notification_nav_logs') || '[]');
              const combinedLogs = [...formattedLogs, ...existingLogs];
              // Keep only the last 10 logs
              while (combinedLogs.length > 10) combinedLogs.pop();
              localStorage.setItem('notification_nav_logs', JSON.stringify(combinedLogs));
              
              // Update state with combined logs
              setNavLogs(combinedLogs);
            } catch (storageErr) {
              console.error('Error storing IndexedDB logs to localStorage:', storageErr);
              // Just use the logs we have
              setNavLogs(prevLogs => [...formattedLogs, ...prevLogs]);
            }
            
            // Clear the IndexedDB store after retrieving logs
            const clearTransaction = db.transaction(['notificationClicks'], 'readwrite');
            const clearStore = clearTransaction.objectStore('notificationClicks');
            clearStore.clear();
          }
        };
        
        getAllRequest.onerror = function(err) {
          console.error('Error getting logs from IndexedDB:', err);
        };
      };
      
      dbRequest.onerror = function(event) {
        console.error('Error opening IndexedDB:', event.target.error);
      };
    } catch (dbError) {
      console.error('Error accessing IndexedDB:', dbError);
    }

    // Get any navigation errors
    try {
      const error = JSON.parse(localStorage.getItem('notification_nav_error') || 'null');
      setNavError(error);
    } catch (e) {
      console.error('Error loading navigation error:', e);
    }

    // Get PWA notification logs
    try {
      const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
      setPwaLogs(logs);
    } catch (e) {
      console.error('Error loading PWA logs:', e);
    }

    // Get device info
    setUserAgent(navigator.userAgent);
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
    setIsPWA(
      window.matchMedia('(display-mode: standalone)').matches || 
      window.navigator.standalone === true
    );
    
    // Record the time of this refresh
    setLastRefresh(new Date());
    
    // Check token status
    updateTokenStatus();
  }, []);

  // Clear logs
  const clearLogs = (type) => {
    if (window.confirm(`Are you sure you want to clear the ${type} logs?`)) {
      switch(type) {
        case 'all':
          localStorage.removeItem('notification_nav_logs');
          localStorage.removeItem('notification_nav_error');
          localStorage.removeItem('pwa_notification_logs');
          clearAuthDebugLogs();
          setNavLogs([]);
          setNavError(null);
          setPwaLogs([]);
          setAuthLogs([]);
          break;
        case 'navigation':
          localStorage.removeItem('notification_nav_logs');
          localStorage.removeItem('notification_nav_error');
          setNavLogs([]);
          setNavError(null);
          break;
        case 'pwa':
          localStorage.removeItem('pwa_notification_logs');
          setPwaLogs([]);
          break;
        case 'auth':
          clearAuthDebugLogs();
          setAuthLogs([]);
          break;
        default:
          break;
      }
    }
  };

  // Test redirect
  const testRedirect = (path) => {
    // Use the direct approach
    window.location.href = window.location.origin + path;
  };
  
  // Manual token refresh
  const handleManualRefresh = async () => {
    try {
      if (window.confirm('This will attempt to refresh your authentication token. Continue?')) {
        // Add a log entry to show manual refresh was triggered
        const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
        logs.push({
          timestamp: new Date().toISOString(),
          message: '[Auth] Manual token refresh triggered by user'
        });
        localStorage.setItem('pwa_notification_logs', JSON.stringify(logs));
        
        // Log the pre-refresh state
        try {
          const beforeToken = localStorage.getItem('accessToken');
          const beforeUserData = localStorage.getItem('userData');
          
          logs.push({
            timestamp: new Date().toISOString(),
            message: `[Auth] Pre-refresh state: token=${beforeToken ? 'present' : 'absent'}, userData=${beforeUserData ? 'present' : 'absent'}`
          });
          localStorage.setItem('pwa_notification_logs', JSON.stringify(logs));
        } catch (e) {
          console.error('Error logging pre-refresh state:', e);
        }
        
        // Log the cookies for debugging
        try {
          const cookieLog = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
          
          // Add more detailed cookie debugging
          const cookieStr = document.cookie;
          const hasRefreshToken = cookieStr.includes('refreshToken') || cookieStr.includes('refresh_token');
          
          cookieLog.push({
            timestamp: new Date().toISOString(),
            message: `[Auth] Cookies before refresh: ${document.cookie}`
          });
          
          cookieLog.push({
            timestamp: new Date().toISOString(),
            message: `[Auth] Cookie analysis: length=${cookieStr.length}, hasRefreshToken=${hasRefreshToken}, httpOnly cookies not visible in JS`
          });
          
          localStorage.setItem('pwa_notification_logs', JSON.stringify(cookieLog));
        } catch (e) {
          console.error('Error logging cookies:', e);
        }
        
        try {
          // Log cookies for debugging
          console.log('Cookies being sent:', document.cookie);
          
          // Use the authInstance from axiosInstance.js for consistency with the rest of your app
          // Note: authInstance already has withCredentials: true set in its config (see axiosInstance.js)
          const response = await authInstance.post('/refresh', {}, {
            withCredentials: true, // Ensure cookies are sent
            credentials: 'include' // Additional reinforcement for cookies
          });
          
          // Log successful request with headers and status
          const reqLog = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
          reqLog.push({
            timestamp: new Date().toISOString(),
            message: '[Auth] Refresh request sent with authInstance succeeded'
          });
          
          // Log response headers for debugging
          const headers = response.headers || {};
          reqLog.push({
            timestamp: new Date().toISOString(),
            message: `[Auth] Response headers: ${JSON.stringify(Object.fromEntries(
              Object.entries(headers).filter(([key]) => 
                key.toLowerCase().includes('cookie') || 
                key.toLowerCase().includes('refresh') ||
                key.toLowerCase().includes('auth')
              )
            ))}`
          });
          
          localStorage.setItem('pwa_notification_logs', JSON.stringify(reqLog));
          
          const data = response.data;
          
          // Save the new access token
          if (data.access_token) {
            localStorage.setItem('accessToken', data.access_token);
            
            // Get stored userData
            const userDataStr = localStorage.getItem('userData');
            if (userDataStr) {
              const userData = JSON.parse(userDataStr);
              
              // Store in IndexedDB as well
              try {
                await storeAuthDataInIndexedDB(data.access_token, userData);
              } catch (dbError) {
                console.error('Error storing to IndexedDB:', dbError);
              }
            }
            
            // Success log
            const resultLogs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
            resultLogs.push({
              timestamp: new Date().toISOString(),
              message: `[Auth] Manual refresh succeeded with new token: ${data.access_token.substring(0, 10)}...`
            });
            localStorage.setItem('pwa_notification_logs', JSON.stringify(resultLogs));
            
            alert('Token refresh succeeded! New token stored.');
          } else {
            // No token in response
            const resultLogs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
            resultLogs.push({
              timestamp: new Date().toISOString(),
              message: `[Auth] Manual refresh API call succeeded but no token returned`
            });
            localStorage.setItem('pwa_notification_logs', JSON.stringify(resultLogs));
            
            alert('API call succeeded but no token was returned. Check logs for details.');
          }
        } catch (error) {
          // API call failed
          console.error('Axios refresh error:', error);
          
          const resultLogs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
          resultLogs.push({
            timestamp: new Date().toISOString(),
            message: `[Auth] Manual refresh API call failed: ${error.response?.status || 'Network Error'} - ${error.message}`
          });
          
          // Log more detailed error info if available
          if (error.response) {
            resultLogs.push({
              timestamp: new Date().toISOString(),
              message: `[Auth] Error response: ${JSON.stringify({
                status: error.response.status,
                statusText: error.response.statusText,
                headers: Object.fromEntries(
                  Object.entries(error.response.headers || {})
                    .filter(([key]) => key.toLowerCase().includes('refresh') || key.toLowerCase().includes('cookie'))
                )
              })}`
            });
          }
          
          localStorage.setItem('pwa_notification_logs', JSON.stringify(resultLogs));
          
          alert(`Token refresh failed: ${error.message}. Check logs for details.`);
        }
        
        // Refresh the UI
        refreshLogs();
      }
    } catch (error) {
      console.error('Error during manual token refresh:', error);
      alert('Error during manual token refresh: ' + error.message);
      
      // Also log the error
      try {
        const errorLogs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
        errorLogs.push({
          timestamp: new Date().toISOString(),
          message: `[Auth] Manual refresh error: ${error.message}`
        });
        localStorage.setItem('pwa_notification_logs', JSON.stringify(errorLogs));
      } catch (e) {
        console.error('Error logging refresh error:', e);
      }
      
      // Refresh logs display
      refreshLogs();
    }
  };
  
  // Update token status
  const updateTokenStatus = async () => {
    // Check localStorage
    const token = localStorage.getItem('accessToken');
    const userDataString = localStorage.getItem('userData');
    let userDataInfo = 'None';
    
    // If userData exists, log its structure for debugging
    try {
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        userDataInfo = `Found (${Object.keys(userData).length} keys)`;
        
        // Log userData details to the PWA logs
        const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
        logs.push({
          timestamp: new Date().toISOString(),
          message: `[Auth] LocalStorage userData structure: ${JSON.stringify(Object.keys(userData))}`
        });
        localStorage.setItem('pwa_notification_logs', JSON.stringify(logs));
      }
    } catch (e) {
      console.error('Error parsing userData:', e);
      userDataInfo = 'Error parsing';
    }
    
    // Check IndexedDB
    let indexedDBData = null;
    try {
      indexedDBData = await getAuthDataFromIndexedDB();
    } catch (e) {
      console.error('Error reading from IndexedDB:', e);
    }
    
    // Log to PWA notification logs
    try {
      const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
      logs.push({
        timestamp: new Date().toISOString(),
        message: `[Auth] IndexedDB check: ${indexedDBData ? 'Data found' : 'No data'}`
      });
      
      // Log detailed IndexedDB data if available
      if (indexedDBData && indexedDBData.userData) {
        logs.push({
          timestamp: new Date().toISOString(),
          message: `[Auth] IndexedDB userData structure: ${JSON.stringify(Object.keys(indexedDBData.userData))}`
        });
      }
      
      localStorage.setItem('pwa_notification_logs', JSON.stringify(logs));
    } catch (e) {
      console.error('Error logging to PWA logs:', e);
    }
    
    setTokenStatus({
      hasLocalStorageToken: !!token,
      hasLocalStorageUserData: !!userDataString,
      tokenFirstChars: token ? token.substring(0, 10) + '...' : 'None',
      userDataId: userDataInfo,
      hasIndexedDBToken: !!(indexedDBData && indexedDBData.token),
      indexedDBTokenFirstChars: indexedDBData?.token ? indexedDBData.token.substring(0, 10) + '...' : 'None',
      indexedDBUserDataId: indexedDBData?.userData ? 'Present' : 'Missing'
    });
  };
  
  // Refresh logs
  const refreshLogs = () => {
    // First, get logs from localStorage
    let localStorageLogs = [];
    try {
      localStorageLogs = JSON.parse(localStorage.getItem('notification_nav_logs') || '[]');
      setNavLogs(localStorageLogs);
    } catch (e) {
      console.error('Error loading navigation logs from localStorage:', e);
    }

    // Get any navigation errors
    try {
      const error = JSON.parse(localStorage.getItem('notification_nav_error') || 'null');
      setNavError(error);
    } catch (e) {
      console.error('Error loading navigation error:', e);
    }

    // Get PWA notification logs
    try {
      const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
      setPwaLogs(logs);
    } catch (e) {
      console.error('Error loading PWA logs:', e);
    }
    
    // Get auth debug logs
    try {
      const logs = getAuthDebugLogs();
      setAuthLogs(logs);
    } catch (e) {
      console.error('Error loading auth debug logs:', e);
    }
    
    // Update token status
    updateTokenStatus();
    
    // Then, check IndexedDB for any logs
    try {
      const dbRequest = indexedDB.open('NotificationDebugDB', 1);
      
      dbRequest.onsuccess = function(event) {
        const db = event.target.result;
        
        if (!db.objectStoreNames.contains('notificationClicks')) {
          console.log('No notification clicks object store found');
          return;
        }
        
        const transaction = db.transaction(['notificationClicks'], 'readonly');
        const store = transaction.objectStore('notificationClicks');
        const getAllRequest = store.getAll();
        
        getAllRequest.onsuccess = function() {
          const indexedDBLogs = getAllRequest.result || [];
          console.log('Retrieved logs from IndexedDB on refresh:', indexedDBLogs);
          
          if (indexedDBLogs.length > 0) {
            // Format logs from IndexedDB
            const formattedLogs = indexedDBLogs.map(log => ({
              timestamp: log.timestamp,
              messageData: log.data || {},
              targetPath: log.data?.navigationPath || log.data?.url || '/',
              deviceInfo: {
                isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent),
                isPWA: window.matchMedia('(display-mode: standalone)').matches || 
                       window.navigator.standalone === true,
                userAgent: navigator.userAgent.substring(0, 50) + '...'
              },
              source: 'IndexedDB (refresh)'
            }));
            
            // Combine with existing logs
            const combinedLogs = [...formattedLogs, ...localStorageLogs];
            // Keep only the last 10 logs
            while (combinedLogs.length > 10) combinedLogs.pop();
            
            // Save combined logs to localStorage
            localStorage.setItem('notification_nav_logs', JSON.stringify(combinedLogs));
            
            // Update state
            setNavLogs(combinedLogs);
            
            // Clear IndexedDB store
            const clearTransaction = db.transaction(['notificationClicks'], 'readwrite');
            const clearStore = clearTransaction.objectStore('notificationClicks');
            clearStore.clear();
          }
        };
      };
    } catch (dbError) {
      console.error('Error accessing IndexedDB during refresh:', dbError);
    }
    
    // Update refresh time
    setLastRefresh(new Date());
  };

  return (
    <Container className="py-4" style={{ maxWidth: '100%', overflowX: 'hidden' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1>App Debug Console</h1>
          <p className="mb-0">This page provides debugging tools for authentication, storage, notifications and navigation.</p>
        </div>
        <Button onClick={refreshLogs} variant="primary">
          Refresh Data
        </Button>
      </div>
      
      <Alert variant="info" className="d-flex justify-content-between align-items-center">
        <div>
          <strong>Debug Session Info</strong>
          <div><small>Last refreshed: {lastRefresh.toLocaleTimeString()}</small></div>
          <div><small>Access method: {accessSource}</small></div>
        </div>
        <div>
          <Badge bg={isIOS ? "warning" : "secondary"} className="me-2">
            {isIOS ? "iOS Device" : "Non-iOS Device"}
          </Badge>
          <Badge bg={isPWA ? "success" : "secondary"}>
            {isPWA ? "Running as PWA" : "Not PWA"}
          </Badge>
        </div>
      </Alert>
      
      <p>
        <strong>Direct Access URL:</strong> <code>{window.location.origin}/?debug=app</code>
        <Button 
          variant="link" 
          size="sm" 
          onClick={() => {
            navigator.clipboard.writeText(`${window.location.origin}/?debug=app`);
            alert('URL copied to clipboard!');
          }}
          className="ms-2"
        >
          Copy
        </Button>
      </p>

      <Card className="mb-4">
        <Card.Header>
          <h2>Authentication Status</h2>
        </Card.Header>
        <Card.Body>
          <Alert variant={tokenStatus.hasLocalStorageToken ? "success" : "danger"}>
            <strong>Token in localStorage:</strong> {tokenStatus.hasLocalStorageToken ? 'Present' : 'Missing'}
            {tokenStatus.hasLocalStorageToken && (
              <div><small>First characters: {tokenStatus.tokenFirstChars}</small></div>
            )}
          </Alert>
          
          <Alert variant={tokenStatus.hasLocalStorageUserData ? "success" : "danger"}>
            <strong>User data in localStorage:</strong> {tokenStatus.hasLocalStorageUserData ? 'Present' : 'Missing'}
            {tokenStatus.hasLocalStorageUserData && (
              <div><small>User ID: {tokenStatus.userDataId}</small></div>
            )}
          </Alert>
          
          <h5 className="mt-3">IndexedDB Storage Status:</h5>
          
          <Alert variant={tokenStatus.hasIndexedDBToken ? "success" : "warning"}>
            <strong>Token in IndexedDB:</strong> {tokenStatus.hasIndexedDBToken ? 'Present' : 'Missing'}
            {tokenStatus.hasIndexedDBToken && (
              <div><small>First characters: {tokenStatus.indexedDBTokenFirstChars}</small></div>
            )}
          </Alert>
          
          <Alert variant={tokenStatus.hasIndexedDBToken && tokenStatus.indexedDBUserDataId !== 'None' ? "success" : "warning"}>
            <strong>User data in IndexedDB:</strong> {tokenStatus.hasIndexedDBToken && tokenStatus.indexedDBUserDataId !== 'None' ? 'Present' : 'Missing'}
            {tokenStatus.hasIndexedDBToken && tokenStatus.indexedDBUserDataId !== 'None' && (
              <div><small>User ID: {tokenStatus.indexedDBUserDataId}</small></div>
            )}
          </Alert>
          
          <Button 
            onClick={updateTokenStatus} 
            variant="outline-primary" 
            size="sm" 
            className="mt-2"
          >
            Refresh Token Status
          </Button>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <h2>Device Information</h2>
        </Card.Header>
        <Card.Body>
          <p style={{ wordBreak: 'break-all' }}><strong>User Agent:</strong> <span style={{ fontSize: '90%' }}>{userAgent}</span></p>
          <p><strong>iOS Device:</strong> {isIOS ? 'Yes' : 'No'}</p>
          <p><strong>Running as PWA:</strong> {isPWA ? 'Yes' : 'No'}</p>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <h2>Debug Actions</h2>
        </Card.Header>
        <Card.Body>
          <h5>Authentication Tools</h5>
          <div className="d-grid gap-2 mb-4">
            <Button onClick={handleManualRefresh} variant="warning">
              Manually Trigger Token Refresh
            </Button>
            
            <Button 
              onClick={async () => {
                try {
                  // Get IndexedDB data directly
                  const db = await new Promise((resolve, reject) => {
                    const request = indexedDB.open('authDB', 1);
                    request.onsuccess = () => resolve(request.result);
                    request.onerror = () => reject(request.error);
                  });
                  
                  // Get the auth data
                  const tx = db.transaction('auth', 'readonly');
                  const store = tx.objectStore('auth');
                  
                  const data = await new Promise((resolve, reject) => {
                    const request = store.get('authData');
                    request.onsuccess = () => resolve(request.result);
                    request.onerror = () => reject(request.error);
                  });
                  
                  // Show the raw data from IndexedDB
                  if (data) {
                    const logs = JSON.parse(localStorage.getItem('pwa_notification_logs') || '[]');
                    logs.push({
                      timestamp: new Date().toISOString(),
                      message: `[Auth] Raw IndexedDB data found: ${JSON.stringify({
                        token: data.token ? data.token.substring(0, 10) + '...' : 'none',
                        userData: data.userData ? Object.keys(data.userData) : 'none', 
                        timestamp: new Date(data.timestamp).toISOString()
                      })}`
                    });
                    
                    // Log user data object structure in detail
                    if (data.userData) {
                      const userDetails = {
                        id: data.userData.id || data.userData._id || 'none',
                        email: data.userData.email || 'none',
                        firstName: data.userData.first_name || data.userData.firstName || 'none',
                        lastName: data.userData.last_name || data.userData.lastName || 'none',
                        fullKeys: Object.keys(data.userData)
                      };
                      
                      logs.push({
                        timestamp: new Date().toISOString(),
                        message: `[Auth] UserData details: ${JSON.stringify(userDetails)}`
                      });
                    }
                    
                    localStorage.setItem('pwa_notification_logs', JSON.stringify(logs));
                    alert('Raw IndexedDB data logged to system logs');
                  } else {
                    alert('No data found in IndexedDB');
                  }
                  
                  // Refresh display
                  refreshLogs();
                } catch (e) {
                  console.error('Error diagnosing IndexedDB:', e);
                  alert('Error diagnosing IndexedDB: ' + e.message);
                }
              }} 
              variant="outline-secondary"
              className="mt-2"
            >
              Diagnose IndexedDB Data
            </Button>
          </div>
          
          <h5>Navigation Tests</h5>
          <div className="d-grid gap-2">
            <Button onClick={() => testRedirect('/schedule-grid')} variant="outline-secondary">
              Test Navigation to Schedule Grid
            </Button>
            <Button onClick={() => testRedirect('/settings')} variant="outline-secondary">
              Test Navigation to Settings
            </Button>
          </div>
        </Card.Body>
      </Card>

      {navError && (
        <Alert variant="danger" className="mb-4">
          <h3>Navigation Error</h3>
          <p><strong>Path:</strong> {navError.path}</p>
          <p><strong>Timestamp:</strong> {navError.timestamp}</p>
          {navError.error && <p><strong>Error:</strong> {navError.error}</p>}
          {navError.error1 && <p><strong>Error 1:</strong> {navError.error1}</p>}
          {navError.error2 && <p><strong>Error 2:</strong> {navError.error2}</p>}
        </Alert>
      )}

      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h2>Navigation Event Logs ({navLogs.length})</h2>
          <Button variant="danger" size="sm" onClick={() => clearLogs('navigation')}>
            Clear Navigation Logs
          </Button>
        </Card.Header>
        <Card.Body style={{ maxHeight: '400px', overflow: 'auto', overflowX: 'hidden', width: '100%' }}>
          {navLogs.length === 0 ? (
            <p>No navigation logs found.</p>
          ) : (
            navLogs.map((log, index) => (
              <div key={index} className="border-bottom pb-3 mb-3" style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                <h5>Navigation Event {index + 1}</h5>
                <p><strong>Timestamp:</strong> {log.timestamp}</p>
                <p><strong>Target Path:</strong> {log.targetPath}</p>
                <div>
                  <p><strong>Device Info:</strong></p>
                  <ul>
                    <li>iOS: {log.deviceInfo?.isIOS ? 'Yes' : 'No'}</li>
                    <li>PWA: {log.deviceInfo?.isPWA ? 'Yes' : 'No'}</li>
                    <li style={{ wordBreak: 'break-all' }}>User Agent: <span style={{ fontSize: '90%' }}>{log.deviceInfo?.userAgent}</span></li>
                  </ul>
                </div>
                <div>
                  <p><strong>Message Data:</strong></p>
                  <pre style={{ 
                    maxHeight: '200px', 
                    overflow: 'auto',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                    fontSize: '85%',
                    backgroundColor: '#f6f8fa',
                    padding: '10px',
                    borderRadius: '4px',
                    maxWidth: '100%'
                  }}>
                    {JSON.stringify(log.messageData, null, 2)}
                  </pre>
                </div>
              </div>
            ))
          )}
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h2>Authentication Debug Logs ({authLogs.length})</h2>
          <Button variant="danger" size="sm" onClick={() => clearLogs('auth')}>
            Clear Auth Logs
          </Button>
        </Card.Header>
        <Card.Body style={{ maxHeight: '400px', overflow: 'auto', overflowX: 'hidden', width: '100%' }}>
          {authLogs.length === 0 ? (
            <p>No authentication debug logs found.</p>
          ) : (
            authLogs.map((log, index) => (
              <div key={index} className="border-bottom pb-3 mb-3" style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                <p><strong>Timestamp:</strong> {log.timestamp}</p>
                <p style={{ wordBreak: 'break-word' }}><strong>Message:</strong> {log.message}</p>
                {log.data && (
                  <div>
                    <p><strong>Data:</strong></p>
                    <pre style={{ 
                      maxHeight: '200px', 
                      overflow: 'auto', 
                      fontSize: '12px',
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-all',
                      backgroundColor: '#f6f8fa',
                      padding: '10px',
                      borderRadius: '4px',
                      maxWidth: '100%'
                    }}>
                      {log.data}
                    </pre>
                  </div>
                )}
              </div>
            ))
          )}
        </Card.Body>
      </Card>
      
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h2>System Event Logs ({pwaLogs.length})</h2>
          <Button variant="danger" size="sm" onClick={() => clearLogs('pwa')}>
            Clear System Logs
          </Button>
        </Card.Header>
        <Card.Body style={{ maxHeight: '400px', overflow: 'auto', overflowX: 'hidden', width: '100%' }}>
          {pwaLogs.length === 0 ? (
            <p>No system event logs found.</p>
          ) : (
            pwaLogs.map((log, index) => (
              <div key={index} className="border-bottom pb-3 mb-3" style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                <p><strong>Timestamp:</strong> {log.timestamp}</p>
                <p style={{ wordBreak: 'break-word' }}><strong>Message:</strong> {log.message}</p>
              </div>
            ))
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AppDebugPage;